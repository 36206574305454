import React from 'react';
import FormElementComponent from './FormElementComponent';

const FormPreview = ({ elements, setFormElements }) => {
    const updateElement = (id, updatedProps) => {
        setFormElements((prevElements) =>
            prevElements.map((element) =>
                element.id === id ? { ...element, ...updatedProps } : element
            )
        );
    };

    const moveElement = (dragIndex, hoverIndex) => {
        setFormElements((prevElements) => {
            const updatedElements = [...prevElements];
            const [draggedElement] = updatedElements.splice(dragIndex, 1);
            updatedElements.splice(hoverIndex, 0, draggedElement);
            return updatedElements;
        });
    };

    const deleteElement = (id) => {
        setFormElements((prevElements) => prevElements.filter((element) => element.id !== id));
    };

    return (
        <div className="bg-white p-4 rounded shadow">
            <h2 className="text-2xl font-bold mb-4">Form Preview</h2>
            {elements.map((element, index) => (
                <FormElementComponent
                    key={element.id}
                    element={element}
                    index={index}
                    updateElement={updateElement}
                    moveElement={moveElement}
                    deleteElement={deleteElement}
                />
            ))}
        </div>
    );
};

export default FormPreview;
