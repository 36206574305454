import React, { useState } from 'react';
import { Trash2, GripVertical, Plus } from 'lucide-react';

// Editable Radio Group Component
const EditableRadioGroup = ({ question, options, onChange }) => {
    const [editableQuestion, setEditableQuestion] = useState(question || "Your Question?");
    const [editableOptions, setEditableOptions] = useState(options || ["Option 1", "Option 2"]);

    const updateOption = (index, value) => {
        const updatedOptions = [...editableOptions];
        updatedOptions[index] = value;
        setEditableOptions(updatedOptions);
        onChange({ question: editableQuestion, options: updatedOptions });
    };

    const removeOption = (index) => {
        const updatedOptions = editableOptions.filter((_, i) => i !== index);
        setEditableOptions(updatedOptions);
        onChange({ question: editableQuestion, options: updatedOptions });
    };

    return (
        <div className="p-6 border rounded-lg bg-white shadow-md hover:shadow-lg transition-shadow">
            <div className="flex items-center mb-4">
                <GripVertical className="w-6 h-6 text-gray-400 cursor-move mr-2" />
                <input
                    type="text"
                    value={editableQuestion}
                    onChange={(e) => {
                        setEditableQuestion(e.target.value);
                        onChange({ question: e.target.value, options: editableOptions });
                    }}
                    className="w-full text-lg font-semibold px-2 py-1 border-b-2 border-transparent hover:border-gray-200 focus:border-blue-500 focus:outline-none transition-colors"
                    placeholder="Enter your question"
                />
            </div>

            <div className="space-y-3">
                {editableOptions.map((option, index) => (
                    <div key={index} className="flex items-center group">
                        <input
                            type="radio"
                            disabled
                            className="w-4 h-4 mr-3 cursor-not-allowed"
                        />
                        <input
                            type="text"
                            value={option}
                            onChange={(e) => updateOption(index, e.target.value)}
                            className="flex-1 px-2 py-1 border-b border-transparent hover:border-gray-200 focus:border-blue-500 focus:outline-none transition-colors"
                            placeholder="Enter option text"
                        />
                        <button
                            onClick={() => removeOption(index)}
                            className="opacity-0 group-hover:opacity-100 p-1 text-gray-400 hover:text-red-500 transition-all"
                        >
                            <Trash2 className="w-4 h-4" />
                        </button>
                    </div>
                ))}
            </div>

            <button
                onClick={() => setEditableOptions([...editableOptions, `Option ${editableOptions.length + 1}`])}
                className="mt-4 flex items-center text-blue-500 hover:text-blue-600 font-medium transition-colors"
            >
                <Plus className="w-4 h-4 mr-1" />
                Add Option
            </button>
        </div>
    );
};

export default EditableRadioGroup;