import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { v4 as uuidv4 } from 'uuid';
import { Button, Box, Toolbar, AppBar } from '@mui/material';
import FormPreview from './FormPreview';
import Toolbox from './Toolbox';
import { Save, SaveIcon } from 'lucide-react';
import CloseIcon from '@mui/icons-material/Close';

const FormBuilder = ({ onClose, onSave }) => {
    const [formElements, setFormElements] = useState([]);
    const [surveyName, setSurveyName] = useState('My Survey');

    const addElement = (type) => {
        const newElement = {
            id: uuidv4(),
            type,
            label: `${type} Label`,
        };
        setFormElements((prevElements) => [...prevElements, newElement]);
    };

    const saveSurvey = () => {
        const newSurvey = {
            id: uuidv4(),
            name: surveyName,
            elements: formElements,
        };
        onSave(newSurvey); // Pass survey data back to the parent
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="space-y-4">
                <input
                    type="text"
                    value={surveyName}
                    onChange={(e) => setSurveyName(e.target.value)}
                    className="text-2xl font-bold w-full p-2 border rounded"
                />
                <div className="flex">
                    <div className="w-3/4">
                        <FormPreview elements={formElements} setFormElements={setFormElements} />
                    </div>
                    <div className="w-1/4">
                        <Toolbox onAddElement={addElement} />
                    </div>
                </div>
                <AppBar
                    position="static"
                    color="default"
                    elevation={1}
                    sx={{
                        top: 'auto',
                        bottom: 0,
                        bgcolor: 'background.paper'
                    }}
                >
                    <Toolbar sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        py: 1
                    }}>
                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                        }}>
                            <Button
                                variant="outlined"
                                startIcon={<CloseIcon />}
                                onClick={onClose}
                                sx={{ width: 120 }}
                            >
                                Close
                            </Button>
                            <Button
                                variant="contained"
                                startIcon={<SaveIcon />}
                                onClick={saveSurvey}
                                sx={{ width: 120 }}
                            >
                                Save
                            </Button>
                        </Box>
                    </Toolbar>
                </AppBar>
            </div>
        </DndProvider>
    );
};

export default FormBuilder;
