import AddBox from '@mui/icons-material/AddBox';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormGroup,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
  Zoom,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { CreateCampaign } from 'Api/Api';
import { SideDrawer } from 'components/SideDrawer';
import { getCurrentDateTimeLocal } from 'utils/commonFunctions';
import CreateTemplateModal from '../Templates/CreateTemplateModal';
import { CampaignStyle } from './Style';
import DateTimePicker from 'components/DateTimePicker';
import { InnerTextField } from 'components/InputFields';
import { CampaignScheuduler } from 'constants/appConstant';
import { CustomSelect } from 'components/CustomSelect';
import CreateSegmentModal from '../Segments/CreateSegmentModal';
import CustomTooltip from 'components/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

const CampaignPushNotification = ({
  open,
  campaignName,
  onClose,
  refresh,
  templates = [],
  segments = [],
  FetchTemplate,
  FetchSegment,
  campaignType,
}) => {
  const [campaignDetails, setCampaignDetails] = useState({
    campaignName: campaignName || '',
    templateID: null,
    segmentID: null,
    frequency: 'now',
    frequencyDateTime: '',
    status: 'Active',
    Type: campaignType || 'Push_Notifications',
    subscribersInteractionNeeded: false,
    selectedCountry: null,
  });

  const [errors, setErrors] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [selectedSegment, setSelectedSegment] = useState();
  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [segmentModalOpen, setSegmentModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);

  const closeModals = () => {
    setTemplateModalOpen(false);
    setSegmentModalOpen(false);
  };

  const handleClose = () => {
    onClose();
    setCampaignDetails({
      campaignName: '',
      templateID: null,
      segmentID: null,
      frequency: 'now',
      frequencyDateTime: '',
      status: 'Active',
      Type: 'Push_Notifications',
    });
  };

  const handleToggle = () => {
    setCampaignDetails((prevState) => ({
      ...prevState,
      status: prevState.status === 'Active' ? 'Inactive' : 'Active',
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCampaignDetails((prev) => ({ ...prev, [name]: checked }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCampaignDetails((prev) => ({
      ...prev,
      [name]: value || null,
    }));
    setErrors((prev) => ({ ...prev, [name]: !value.trim() }));
  };

  const handleFrequencyChange = (event) => {
    const { value } = event.target;
    setCampaignDetails((prev) => ({
      ...prev,
      frequency: value,
      frequencyDateTime:
        value !== 'on_specific_date'
          ? getCurrentDateTimeLocal()
          : prev.frequencyDateTime,
    }));
    setErrors((prev) => ({ ...prev, frequency: !value.trim() }));
  };

  const handleSubmit = async () => {
    const newErrors = {
      campaignName: !campaignDetails.campaignName.trim(),
      templateID: campaignDetails.templateID === null,
      segmentID: campaignDetails.segmentID === null,
    };
    setErrors(newErrors);

    if (Object.values(newErrors).every((val) => !val)) {
      setLoading(true);
      try {
        let formData = new FormData();
        Object.entries(campaignDetails).forEach(([key, value]) => {
          if (key === 'templateID' || key === 'segmentID') {
            formData.append(key, value ? parseInt(value) : null);
          } else {
            formData.append(key, value);
          }
        });

        const response = await CreateCampaign(formData);
        if (response?.data?.status === true) {
          toast.success(response?.data?.message);
          refresh();
          handleClose();
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error('saf?.data?.message');
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (campaignDetails.frequency === 'now') {
      setCampaignDetails((prev) => ({
        ...prev,
        frequencyDateTime: getCurrentDateTimeLocal(),
      }));
    }
  }, [campaignDetails.frequency]);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();
        const countryNames = data.map((country) => ({
          label: country.name.common,
        }));
        const completeCountries = [
          { label: 'Subscriber Local Timezone' },
          ...countryNames,
        ];
        setCountries(completeCountries);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };
    getCountries();
  }, []);

  return (
    <>
      <SideDrawer
        open={open}
        onClose={onClose}
        title="Create Campaign"
        handleSubmit={handleSubmit}
      >
        <Grid item xs={12} md={10.5}>
          <Box>
            <InnerTextField
              required
              margin="dense"
              name="campaignName"
              label="Campaign Name"
              onChange={handleChange}
              error={errors.campaignName}
              value={campaignDetails?.campaignName || ''}
              helperText={errors.campaignName && 'Field required'}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={12}>
          <Box sx={CampaignStyle.selectInputFlex} my={2}>
            <Autocomplete
              disablePortal
              options={templates}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.templateName}
              value={selectedTemplate}
              onChange={(e, newValue) => {
                setSelectedTemplate(newValue);
                setCampaignDetails((prev) => ({
                  ...prev,
                  templateID: newValue ? parseInt(newValue.id) : null,
                }));
                setErrors((prev) => ({ ...prev, templateID: !newValue }));
              }}
              sx={CampaignStyle.autoSelectStyle}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Template"
                  error={errors.templateID}
                  helperText={errors.templateID && 'Field required'}
                  InputLabelProps={{ style: { color: 'black' } }}
                  InputProps={{
                    ...params.InputProps,
                    style: { color: 'black' },
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} style={{ color: 'black' }}>
                  {option.templateName}
                </li>
              )}
            />
            <IconButton onClick={() => setTemplateModalOpen(true)}>
              <AddBox
                sx={{ color: '#058270', height: '50px', width: '50px' }}
              />
            </IconButton>
            <CreateTemplateModal
              refresh={FetchTemplate}
              open={templateModalOpen}
              onClose={closeModals}
              welcome={false}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={12}>
          <Box sx={CampaignStyle.selectInputFlex} my={2}>
            <Autocomplete
              disablePortal
              options={segments}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.segmentName}
              value={selectedSegment}
              onChange={(e, newValue) => {
                setSelectedSegment(newValue);
                setCampaignDetails((prev) => ({
                  ...prev,
                  segmentID: newValue ? parseInt(newValue.id) : null,
                }));
                setErrors((prev) => ({ ...prev, segmentID: !newValue }));
              }}
              sx={CampaignStyle.autoSelectStyle}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Segment"
                  error={errors.segmentID}
                  helperText={errors.segmentID && 'Field required'}
                  InputLabelProps={{ style: { color: 'black' } }}
                  InputProps={{
                    ...params.InputProps,
                    style: { color: 'black' },
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} style={{ color: 'black' }}>
                  {option.segmentName}
                </li>
              )}
            />
            <IconButton onClick={() => setSegmentModalOpen(true)}>
              <AddBox
                sx={{ color: '#058270', height: '50px', width: '50px' }}
              />
            </IconButton>
            <CreateSegmentModal
              refresh={FetchSegment}
              open={segmentModalOpen}
              onClose={closeModals}
            />
          </Box>
        </Grid>

        <Box mb={2} display={'flex'}>
          <Typography sx={{ fontSize: 20, color: 'black' }}>Status</Typography>
          <Switch
            name="status"
            size="large"
            checked={campaignDetails?.status === 'Active'}
            onChange={handleToggle}
          />
        </Box>

        <Box width="100%" display={'flex'} alignItems={'end'} gap={2}>
          <FormControl sx={{ width: '44%' }}>
            <CustomSelect
              label="Schedule"
              margin="normal"
              fullWidth
              error={errors.frequency}
              options={CampaignScheuduler}
              value={campaignDetails?.frequency}
              onChange={handleFrequencyChange}
              helperText={errors.frequency ? 'Field required' : ''}
            />
          </FormControl>

          {/* Need to change this component with Date time picker of MUI */}
          {campaignDetails.frequency === 'on_specific_date' && (
            <DateTimePicker
              label={'Date and time'}
              onChangeval={(e) => {
                setCampaignDetails({
                  ...campaignDetails,
                  frequencyDateTime: e,
                });
              }}
            />
          )}
        </Box>

        {campaignDetails.frequency !== 'now' && (
          <Box>
            <Box display="flex" alignItems="center" mt={3}>
              <Typography
                variant="black_h4"
                sx={{ fontSize: 18, mr: 1, mb: 1 }}
              >
                Subscriber Time Zone
              </Typography>
              <CustomTooltip
                title="This option triggers the workflow according to the subscriber's time zone."
                arrow
                placement="top"
                TransitionComponent={Zoom}
              >
                <IconButton size="small" sx={{ padding: 0 }}>
                  <InfoIcon fontSize="small" />
                </IconButton>
              </CustomTooltip>
            </Box>

            {/* Autocomplete Section */}
            <Box mt={1}>
              <FormControl fullWidth sx={{ maxWidth: '230px' }}>
                <Autocomplete
                  disablePortal
                  autoHighlight
                  options={countries}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  getOptionLabel={(option) => option.label}
                  onChange={(e, newValue) => {
                    setCampaignDetails((prev) => ({
                      ...prev,
                      selectedCountry: newValue ? newValue.label : null,
                    }));
                    setErrors((prev) => ({
                      ...prev,
                      selectedCountry: !newValue,
                    }));
                  }}
                  sx={CampaignStyle.autoSelectStyle}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a country"
                      error={errors.selectedCountry}
                      helperText={errors.selectedCountry && 'Field required'}
                      InputLabelProps={{ style: { color: 'black' } }}
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: 'new-password',
                        style: { color: 'black' },
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      key={`${option.label}-1`}
                      style={{ color: 'black' }}
                    >
                      {option.label}
                    </li>
                  )}
                />
              </FormControl>
            </Box>
          </Box>
        )}

        {/* Subscribers Interaction Checkbox Section */}
        <Box ml={-1} mt={3}>
          <FormGroup>
            <Box display="flex" alignItems="center">
              <Checkbox
                checked={campaignDetails?.subscribersInteractionNeeded}
                onChange={handleCheckboxChange}
                name="subscribersInteractionNeeded"
              />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '1.1rem',
                  color: 'black',
                }}
              >
                Subscribers Interaction Needed
              </Typography>

              <CustomTooltip
                title="This option requires interaction from subscribers to proceed."
                arrow
                placement="top"
                TransitionComponent={Zoom}
              >
                <IconButton size="small" sx={{ padding: 0, ml: 1 }}>
                  <InfoIcon fontSize="small" />
                </IconButton>
              </CustomTooltip>
            </Box>
          </FormGroup>
        </Box>
      </SideDrawer>
    </>
  );
};

export default CampaignPushNotification;