import React from 'react';
import { Button } from '@mui/material';

const Toolbox = ({ onAddElement }) => {
    const elementTypes = [
        { type: 'RadioGroup', label: 'Radio Group' },
        { type: 'CheckboxGroup', label: 'Checkbox Group' },
        { type: 'Dropdown', label: 'Dropdown' },
        { type: 'TextInput', label: 'Text Input' },
    ];

    return (
        <div className="bg-gray-100 p-4 rounded shadow">
            <h2 className="text-xl font-bold mb-4">Toolbox</h2>
            {elementTypes.map(({ type, label }) => (
                <Button
                    key={type}
                    onClick={() => onAddElement(type)}
                    className="w-full"
                    variant="outlined"
                    color="primary"
                    sx={{ marginBottom: '8px' }}
                >
                    Add {label}
                </Button>
            ))}
        </div>
    );
};

export default Toolbox;
