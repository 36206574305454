import { useState } from 'react';
import { 
  Typography, 
  Stack, 
  Box, 
  Checkbox, 
  FormControlLabel, 
  IconButton 
} from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { CustomFormInput } from 'components/Style';
import { RoundedButton } from 'components/Buttons';
import { RegisterUser } from 'Api/Api';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  MobileNumberValidation,
  isEmailValid,
} from 'utils/commonFunctions';
import { useForm } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CustomTooltip from 'components/Tooltip';

export const SignUpContainer = ({ showLogin }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({ 
    mode: 'onChange',
    defaultValues: {
      termsAccepted: false
    }
  });

  const [userDetails, setUserDetails] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  function onChangeReCaptcha(e) {
    setUserDetails((x) => ({ ...x, ['reCaptcha']: e }));
  }

  const validatePassword = (value) => {
    if (!value) return 'Password is required';
    if (value.length < 8) return 'Password must be at least 8 characters';
    if (!/[A-Z]/.test(value)) return 'Password must contain an uppercase letter';
    if (!/[a-z]/.test(value)) return 'Password must contain a lowercase letter';
    if (!/[0-9]/.test(value)) return 'Password must contain a number';
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) return 'Password must contain a special character';
    return true;
  };

  const validateConfirmPassword = (value) => {
    const password = watch('password');
    return value === password || 'Passwords do not match';
  };

  const handleSignup = async (data) => {
    if (!userDetails.reCaptcha) {
      toast.error("Please verify that you're not a robot");
      return;
    }
    
    if (!data.termsAccepted) {
      toast.error('Please accept the Terms and Conditions');
      return;
    }

    setLoading(true);
    try {
      const response = await toast.promise(RegisterUser(data), {
        loading: 'Creating your account...',
        success: (response) => response?.data?.message,
        error: (error) => error?.response?.data?.message,
      });
      
      if (response?.data?.status === true) {
        toast.success('Account created successfully! Redirecting...');
        setTimeout(() => {
          navigate(0);  // Reload to switch to login
        }, 1500);
      }
    } catch (error) {
      // Error handling is done by toast.promise
    } finally {
      setLoading(false);
    }
  };

  const openTermsInNewTab = () => {
    window.open('/termsandcondition', '_blank', 'noopener,noreferrer');
  };
  
  return (
    <Box
      sx={{
        padding: 4,
        boxShadow: 6,
        borderRadius: 3,
        width: '100%',
        maxWidth: 450,
        boxSizing: 'border-box',
        background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
        height: '90vh',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box 
        sx={{ 
          position: 'absolute', 
          top: 0, 
          left: 0, 
          right: 0, 
          background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)', 
          zIndex: 10,
          pb: 2,
          pt: 4,
        }}
      >
        <Typography variant="h4" textAlign="center" sx={{ fontWeight: 'bold', mb: 2 }}>
          Create Account
        </Typography>
        <Typography variant="subtitle1" textAlign="center" color="text.secondary" sx={{ mb: 3 }}>
          Start engaging your customers with Lets Notify
        </Typography>
      </Box>
      
      <Box
        sx={{
          height: 'calc(100% - 60px)',  
          overflowY: 'auto',
          mt: '150px',  
          mb: '50px',   
          pr: 1,        
          pb: '80px',   
          '&::-webkit-scrollbar': {
            display: 'none'  
          },
          msOverflowStyle: 'none',  
          scrollbarWidth: 'none',   
        }}
      >
        <form onSubmit={handleSubmit(handleSignup)}>
          <Stack 
            direction={'column'} 
            spacing={3} 
            sx={{ 
              px: 1, 
              pb: '80px',  
              position: 'relative',
              zIndex: 1 
            }}
          >
            <Box>
              <Typography mb={1} variant="subtitle2">
                Company / Website Name
              </Typography>
              <CustomFormInput
                placeholder="Enter company name"
                {...register('companyName', {
                  required: 'Company name is required',
                  minLength: { 
                    value: 2, 
                    message: 'Company name must be at least 2 characters' 
                  }
                })}
              />
              {errors.companyName && (
                <Typography sx={{ color: 'error.main', mt: 1, fontSize: '0.75rem' }}>
                  {errors?.companyName?.message}
                </Typography>
              )}
            </Box>

            <Box>
              <Typography mb={1} variant="subtitle2">
                Full Name
              </Typography>
              <CustomFormInput
                placeholder="Enter your full name"
                {...register('userName', { 
                  required: 'Full name is required',
                  minLength: { 
                    value: 2, 
                    message: 'Name must be at least 2 characters' 
                  }
                })}
              />
              {errors.userName && (
                <Typography sx={{ color: 'error.main', mt: 1, fontSize: '0.75rem' }}>
                  {errors?.userName?.message}
                </Typography>
              )}
            </Box>

            <Box>
              <Typography mb={1} variant="subtitle2">
                Role
              </Typography>
              <CustomFormInput
                placeholder="Your role in the company"
                {...register('role', {
                  required: 'Role is required',
                })}
              />
              {errors.role && (
                <Typography sx={{ color: 'error.main', mt: 1, fontSize: '0.75rem' }}>
                  {errors?.role?.message}
                </Typography>
              )}
            </Box>

            <Box>
              <Typography mb={1} variant="subtitle2">
                Email Address
              </Typography>
              <CustomFormInput
                placeholder="Enter your email"
                {...register('email', {
                  required: 'Email is required',
                  validate: {
                    validEmail: (value) =>
                      isEmailValid(value) || 'Please enter a valid email address',
                  },
                })}
              />
              {errors.email && (
                <Typography sx={{ color: 'error.main', mt: 1, fontSize: '0.75rem' }}>
                  {errors?.email?.message}
                </Typography>
              )}
            </Box>

            <Box>
              <Typography mb={1} variant="subtitle2">
                Mobile Number
              </Typography>
              <CustomFormInput
                placeholder="+91 1234567890"
                {...register('mobileNumber', {
                  required: 'Mobile number is required',
                  validate: {
                    validMobile: (value) =>
                      MobileNumberValidation(value) || 'Mobile number is not valid',
                  },
                })}
              />
              {errors.mobileNumber && (
                <Typography sx={{ color: 'error.main', mt: 1, fontSize: '0.75rem' }}>
                  {errors?.mobileNumber?.message}
                </Typography>
              )}
            </Box>

            <Box>
              <Typography mb={1} variant="subtitle2">
                Password
              </Typography>
              <Box sx={{ position: 'relative' }}>
                <CustomFormInput
                  placeholder="Create your password"
                  type={showPassword ? 'text' : 'password'}
                  {...register('password', {
                    validate: validatePassword,
                  })}
                />
                <IconButton
                  disableRipple
                  onClick={() => setShowPassword((prev) => !prev)}
                  sx={{
                    position: 'absolute',
                    right: 10,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    color: 'text.secondary',
                  }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </Box>
              {errors.password && (
                <Typography sx={{ color: 'error.main', mt: 1, fontSize: '0.75rem' }}>
                  {errors?.password?.message}
                </Typography>
              )}
            </Box>

            <Box>
              <Typography mb={1} variant="subtitle2">
                Confirm Password
              </Typography>
              <Box sx={{ position: 'relative' }}>
                <CustomFormInput
                  placeholder="Confirm your password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  {...register('confirmPassword', {
                    validate: validateConfirmPassword,
                  })}
                />
                <IconButton
                  disableRipple
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
                  sx={{
                    position: 'absolute',
                    right: 10,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    color: 'text.secondary',
                  }}
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </Box>
              {errors.confirmPassword && (
                <Typography sx={{ color: 'error.main', mt: 1, fontSize: '0.75rem' }}>
                  {errors?.confirmPassword?.message}
                </Typography>
              )}
            </Box>

            <Box>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                onChange={onChangeReCaptcha}
              />
            </Box>

            <Box>
              <FormControlLabel
                control={
                  <Checkbox 
                    {...register('termsAccepted')} 
                    color="primary" 
                  />
                }
                label={
                  <Typography>
                    I accept the{' '}
                    <span 
                      onClick={openTermsInNewTab}
                      style={{ 
                        color: 'blue', 
                        textDecoration: 'underline', 
                        cursor: 'pointer' 
                      }}
                    >
                      Terms and Conditions
                    </span>
                  </Typography>
                }
              />
            </Box>
            
            <RoundedButton 
              title="Create Account" 
              type="submit" 
              disabled={loading}
              sx={{ 
                py: 1.5,
                mt: 2,
                position: 'sticky',  
                bottom: 10,
                zIndex: 10,
                width: '100%',
                backgroundColor: 'white',
                boxShadow: '0 -2px 10px rgba(0,0,0,0.1)'
              }}
            />
          </Stack>
        </form>
      </Box>
      
      <Box 
        sx={{ 
          position: 'absolute', 
          bottom: 0, 
          left: 0, 
          right: 0,
          background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)', 
          zIndex: 5,  
          pt: 2,
          pb: 4,
          textAlign: 'center' 
        }}
      >
        <Typography variant="subtitle2">
          Already have an account?{' '}
          <span
            style={{ 
              textDecoration: 'underline', 
              cursor: 'pointer',
              color: 'primary.main'
            }}
            onClick={showLogin}
          >
            Sign in
          </span>
        </Typography>
      </Box>
    </Box>
  );
};
