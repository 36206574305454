import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import React, { useState } from 'react';
import { SideDrawer } from 'components/SideDrawer';
import { Loading } from 'components/Loading';
import CampaignPushNotification from './CampaignPushNotification';
import CampaignSocialProof from './CampaignSocialProof';
import { InnerTextField } from 'components/InputFields';

const CreateCampaignModal = ({
  open,
  onClose,
  refresh,
  templates = [],
  segments = [],
  FetchTemplate,
  FetchSegment,
  openRealTime,
  refreshRealTime,
}) => {
  const [campaignName, setCampaignName] = useState('');
  const [campaignType, setCampaignType] = useState('Push_Notifications');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [firstStep, setFirstStep] = useState(true);

  const handleClose = () => {
    onClose();
    setCampaignName('');
    setCampaignType('Push_Notifications');
    setFirstStep(true);
  };

  return (
    <>
      <Loading state={loading} />
      <SideDrawer
        open={open}
        onClose={handleClose}
        title="Create Campaign"
        handleSubmit={
          firstStep ? () => setFirstStep(false) : () => setFirstStep(true)
        }
        firstStep={firstStep}
      >
        {firstStep ? (
          <Grid container spacing={0}>
            <Grid item xs={12} md={10.5}>
              <Box>
                <InnerTextField
                  required
                  name="campaignName"
                  label="Campaign Name"
                  value={campaignName}
                  onChange={(e) => setCampaignName(e.target.value)}
                  error={errors.campaignName}
                  helperText={errors.campaignName && 'Field required'}
                  fullWidth
                  sx={{ marginBottom: 2 }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography mt={1} sx={{ color: 'black', fontWeight: 'bold' }}>
                Type
              </Typography>
              <FormControl component="fieldset" fullWidth>
                <RadioGroup
                  name="Type"
                  value={campaignType}
                  onChange={(e) => setCampaignType(e.target.value)}
                >
                  <Paper
                    variant="outlined"
                    sx={{
                      p: 3,
                      my: 2,
                      borderColor: '#036355',
                      display: 'flex',
                      flexDirection: 'column', // Ensure the content aligns vertically
                      paddingLeft: 3,
                      paddingRight: 3,
                      borderRadius: 2,
                    }}
                  >
                    <FormControlLabel
                      value="Push_Notifications"
                      control={<Radio />}
                      label={
                        <Typography sx={{ fontWeight: 'bold', color: 'black' }}>
                          Push Notifications
                        </Typography>
                      }
                    />
                    <Typography
                      variant="body2"
                      sx={{ pl: 4, color: 'textSecondary' }}
                    >
                      A push notification is a real-time, clickable message sent
                      directly to subscribers' devices, providing instant
                      updates or promotional alerts.
                    </Typography>
                  </Paper>

                  <Paper
                    variant="outlined"
                    sx={{
                      p: 3,
                      my: 2,
                      borderColor: '#036355',
                      display: 'flex',
                      flexDirection: 'column', // Ensure the content aligns vertically
                      paddingLeft: 3,
                      paddingRight: 3,
                      borderRadius: 2,
                    }}
                  >
                    <FormControlLabel
                      value="Social_Proof"
                      control={<Radio />}
                      label={
                        <Typography sx={{ fontWeight: 'bold', color: 'black' }}>
                          Social Proof
                        </Typography>
                      }
                    />
                    <Typography
                      variant="body2"
                      sx={{ pl: 4, color: 'textSecondary' }}
                    >
                      It enhances trust and sales by leveraging social proof and
                      FOMO notifications. Displaying real-time customer actions
                      like purchases, sign-ups, or reviews, it creates urgency
                      and boosts conversion rates.
                    </Typography>
                  </Paper>
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        ) : campaignType === 'Push_Notifications' ? (
          <CampaignPushNotification
            FetchSegment={FetchSegment}
            FetchTemplate={FetchTemplate}
            segments={segments}
            templates={templates}
            campaignName={campaignName}
            onClose={handleClose}
            open={open}
            refresh={refresh}
            campaignType={campaignType}
          />
        ) : (
          <CampaignSocialProof
            open={open}
            refresh={refresh}
            onClose={handleClose}
            FetchSegment={FetchSegment}
            FetchTemplate={FetchTemplate}
            segments={segments}
            templates={templates}
            campaignType={campaignType}
            campaignName={campaignName}
          />
        )}
      </SideDrawer>
    </>
  );
};

export default CreateCampaignModal;
