import React, { useEffect, useState } from 'react';
import { 
  Box, 
  Skeleton, 
  TextField, 
  Typography, 
  Grid, 
  useTheme, 
  useMediaQuery, 
  Button,
  Tooltip,
  IconButton,
  Modal
} from '@mui/material';
import { UpdateConnection } from 'Api/Api';
import toast from 'react-hot-toast';
import { Loading, SkeletonLoading } from 'components/Loading';
import { SideDrawer } from 'components/SideDrawer';
import { imageFileInput } from '../Templates/Style';
import { InnerTextField } from 'components//InputFields';
import { formatDateTime, imageValidation } from 'utils/commonFunctions';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CloseIcon from '@mui/icons-material/Close';

const EditConnectModal = ({ open, onClose, itemId, isDetail, refresh }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const [connectionDetails, setConnectionDetails] = useState({});
  const [updateConnection, setUpdateConnection] = useState({
    id: itemId?.id,
  });
  const [image, setImage] = useState();
  const [imagePreview, setImagePreview] = useState(null);
  const [fullImagePreview, setFullImagePreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [errors, setErrors] = useState({
    connectionName: false,
    connectionImage: false,
  });

  const handleEditChange = () => {
    setEdit(!edit);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConnectionDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setUpdateConnection((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() === '' ? true : false,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === 'connectionImage') {
      const file = files[0];
      if (file) {
        const isValid = imageValidation(file, setErrors);
        if (isValid) {
          setConnectionDetails((prevState) => ({
            ...prevState,
            [name]: file,
          }));
          setUpdateConnection((prevState) => ({
            ...prevState,
            [name]: file,
          }));
          
          // Create image preview
          const reader = new FileReader();
          reader.onloadend = () => {
            setImagePreview(reader.result);
          };
          reader.readAsDataURL(file);
        }
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: !file,
        }));
      }
    }
  };

  const handleImageRemove = () => {
    setImagePreview(null);
    setConnectionDetails((prevState) => ({
      ...prevState,
      connectionImage: null,
    }));
    setUpdateConnection((prevState) => ({
      ...prevState,
      connectionImage: null,
    }));
  };

  const handleImagePreview = () => {
    setFullImagePreview(!fullImagePreview);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {
      connectionName: connectionDetails.connectionName.trim() === '',
    };
    setErrors(newErrors);
    if (Object.values(newErrors).every((error) => !error)) {
      try {
        setLoading(true);
        let formData = new FormData();
        Object.entries(updateConnection).forEach(([key, value]) => {
          formData.append(key, value);
        });
        const response = await UpdateConnection(
          connectionDetails?.id,
          formData
        );
        if (response?.data?.status === true) {
          toast.success(response?.data?.message);
          refresh();
          onClose();
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    const { imageURL, ...restItemId } = itemId || {};
    setConnectionDetails(restItemId);
    setUpdateConnection({ id: restItemId.id });
    if (imageURL) {
      setImage(imageURL);
      setImagePreview(imageURL);
    }
  }, [itemId]);

  return (
    <>
      <Loading state={loading} />
      <SideDrawer
        open={open}
        onClose={onClose}
        isDetail={isDetail}
        edit={edit}
        setEdit={handleEditChange}
        title={!edit && isDetail ? 'Connection Details' : 'Edit Connection'}
        handleSubmit={handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InnerTextField
              required
              fullWidth
              error={errors.connectionName}
              name="connectionName"
              label="Connection Name"
              value={connectionDetails?.connectionName || ''}
              placeholder="Enter connection name"
              onChange={handleChange}
              helperText={errors.connectionName ? 'Field required' : ''}
              InputProps={{
                readOnly: !edit && isDetail,
              }}
              sx={{
                '& .MuiInputBase-input': {
                  cursor: !edit && isDetail ? 'default' : 'text',
                }
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography 
              variant="subtitle1" 
              fontWeight={500} 
              mb={1}
            >
              Connection Image
            </Typography>
            <Box 
              sx={{
                position: 'relative',
                width: '100%',
                maxWidth: { xs: '250px', sm: '300px', md: '400px' },
                aspectRatio: '16/9',
                borderRadius: 2,
                overflow: 'hidden',
                boxShadow: 2,
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.02)',
                }
              }}
            >
              {imagePreview ? (
                <>
                  <img
                    src={imagePreview}
                    alt="Connection Image"
                    style={{
                      width: '100%', 
                      height: '100%', 
                      objectFit: 'cover'
                    }}
                  />
                  <Tooltip title="View Full Image">
                    <Button
                      onClick={handleImagePreview}
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        backgroundColor: 'rgba(255,255,255,0.7)',
                        minWidth: 'auto',
                        p: 1,
                        borderRadius: '50%',
                        '&:hover': {
                          backgroundColor: 'rgba(255,255,255,0.9)',
                        }
                      }}
                    >
                      <ZoomInIcon fontSize="small" />
                    </Button>
                  </Tooltip>
                </>
              ) : (
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  No image uploaded
                </Typography>
              )}
            </Box>

            {(edit || !isDetail) && (
              <input 
                type="file" 
                name="connectionImage" 
                accept="image/*" 
                onChange={handleFileChange} 
                style={{ marginTop: '10px' }}
              />
            )}
          </Grid>
        </Grid>
      </SideDrawer>

      {/* Full Image Preview Modal */}
      {fullImagePreview && imagePreview && (
        <Modal
          open={fullImagePreview}
          onClose={handleImagePreview}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box 
            sx={{
              maxWidth: '90%',
              maxHeight: '90%',
              outline: 'none',
              position: 'relative',
            }}
          >
            <IconButton
              onClick={handleImagePreview}
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                backgroundColor: 'rgba(0,0,0,0.5)',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.7)',
                }
              }}
            >
              <CloseIcon />
            </IconButton>
            <img 
              src={imagePreview} 
              alt="Full Image" 
              style={{
                width: '100%', 
                height: '100%', 
                objectFit: 'contain',
                maxWidth: '100%',
                maxHeight: '100%',
              }} 
            />
          </Box>
        </Modal>
      )}
    </>
  );
};

export default EditConnectModal;
