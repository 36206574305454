import React, { useState } from 'react';
import logoSrc from 'assets/Images/Common/logo.png';
import questionImage from 'assets/Images/UnProtected/Question-page.png';
import { useMediaQuery } from '@mui/material';
import {
  IconButton,
  Box,
  FormControl,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { protectedRoutes } from 'constants/appRoutes';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import { GetUserDetails, SaveAboutUser } from 'Api/Api';
import { OuterInputField } from 'components/InputFields';
import { SignupSelect } from './Style';
import { RoundedButton } from 'components/Buttons';
import { useForm } from 'react-hook-form';
import { CustomFormInput, loginScrollbar } from 'components/Style';

const customStyles = {
  inputField: {
    transition: 'all 0.3s ease',
    borderRadius: '8px',
    padding: '12px 15px',
    fontSize: '0.95rem',
    '&:focus': {
      boxShadow: '0 0 8px rgba(8, 255, 217, 0.3)',
      borderColor: '#08FFD9',
    }
  },
  typography: {
    title: {
      fontWeight: 600,
      color: '#044E43',
      letterSpacing: '-0.5px'
    },
    subtitle: {
      color: '#2c3e50',
      fontWeight: 500
    }
  },
  select: {
    '&:hover': {
      backgroundColor: 'rgba(8, 255, 217, 0.05)'
    }
  }
};

const AboutCustomer = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [questionDetails, setQuestionDetails] = useState({
    everusedpushnotification: '',
  });
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuestionDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClick = async (data) => {
    if (!questionDetails.everusedpushnotification) {
      toast.error('Select a question');
      return;
    }
    const combinedData = { ...data, ...questionDetails };
    try {
      const saveResponse = await SaveAboutUser(combinedData);
      if (saveResponse?.data?.status === true) {
        toast.success(saveResponse?.data?.message);
        const userResponse = await GetUserDetails();
        if (
          userResponse?.data?.status === true &&
          userResponse?.data?.data?.customerData !== null
        ) {
          const userDetails = userResponse?.data?.data;
          localStorage.setItem('userDetails', JSON.stringify(userDetails));
          navigate(protectedRoutes.createConnect);
        }
      } else {
        setLoading(false);
        toast.error(saveResponse?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        backgroundColor: '#044E43',
        display: 'flex',
        overflow: { sm: 'auto', md: 'hidden' },
      }}
    >
      <Grid container sx={{ flex: 1, height: '100%' }}>
        <Grid
          item
          md={7.5}
          xs={12}
          sx={{
            display: { sm: 'none', xs: 'none', md: 'flex' },
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            padding: 3,
            boxSizing: 'border-box',
          }}
        >
          <Box display="flex" alignItems="center" mb={2}>
            <IconButton disableRipple>
              <img width={50} src={logoSrc} alt="Logo" />
            </IconButton>
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: '2rem',
                color: '#FFFFFF',
              }}
            >
              Lets Notify
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            <Box width="70%" pl={4} pt={0} pb={4} mb={4}>
              <Typography color="#FFFFFF" fontSize="1.8rem">
                <span
                  style={{
                    fontSize: '1.8rem',
                    fontWeight: 'bold',
                    color: '#08FFD9',
                  }}
                >
                  Engage, inform, and delight
                </span>
                — our push notifications have you covered
              </Typography>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'transparent',
                marginTop: -1,
              }}
            >
              <img
                src={questionImage}
                style={{ maxWidth: '60%', marginTop: '0px' }}
                alt="Form Filling"
              />
            </Box>
          </Box>
        </Grid>

        <Grid item md={4.5} xs={12}>
          <Box
            height="100vh"
            width="100%"
            backgroundColor="#FFFFFF"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              padding: 3,
              boxSizing: 'border-box',
            }}
          >
            <Box
              sx={{
                ...loginScrollbar,
                padding: 3,
                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                borderRadius: 2,
                width: '100%',
                height: '100%',
                boxSizing: 'border-box',
                overflowY: 'scroll',
              }}
            >
              {isSmallScreen ? (
                <>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <IconButton disableRipple>
                      <img width={50} src={logoSrc} alt="Logo" />
                    </IconButton>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '1.8rem',
                        color: '#000000',
                      }}
                    >
                      Lets Notify
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Typography variant="h5" textAlign="center">
                    Almost done!
                  </Typography>
                </>
              )}
              <Box textAlign="center">
                <Typography variant="h7">
                  {isSmallScreen
                    ? 'Let us know more about you'
                    : 'Let’s make it official with a bit more info.'}
                </Typography>
              </Box>

              <form>
                <Stack direction={'column'} spacing={3} mt={3} mb={2}>
                  <Box>
                    <Typography 
                      variant="subtitle2" 
                      sx={customStyles.typography.subtitle}
                    >
                      Industry
                    </Typography>
                    <CustomFormInput
                      {...register('industry', {
                        required: 'Field required*',
                      })}
                      sx={{
                        ...customStyles.inputField,
                        borderColor: errors.industry ? 'error.main' : 'divider'
                      }}
                    />
                    {errors.industry && (
                      <Typography 
                        sx={{ 
                          color: 'error.main', 
                          mt: 0.5, 
                          fontSize: '0.75rem' 
                        }}
                      >
                        {errors?.industry?.message}
                      </Typography>
                    )}
                  </Box>

                  <Box>
                    <Typography 
                      variant="subtitle2" 
                      sx={customStyles.typography.subtitle}
                    >
                      Business Model
                    </Typography>
                    <CustomFormInput
                      {...register('business', {
                        required: 'Field required*',
                      })}
                      sx={{
                        ...customStyles.inputField,
                        borderColor: errors.business ? 'error.main' : 'divider'
                      }}
                    />
                    {errors.business && (
                      <Typography 
                        sx={{ 
                          color: 'error.main', 
                          mt: 0.5, 
                          fontSize: '0.75rem' 
                        }}
                      >
                        {errors?.business?.message}
                      </Typography>
                    )}
                  </Box>

                  <Box>
                    <Typography 
                      variant="subtitle2" 
                      sx={customStyles.typography.subtitle}
                    >
                      What are the challenges are you facing and solutions that
                      are you looking for?
                    </Typography>
                    <CustomFormInput
                      multiline
                      rows={3}
                      {...register('challenges', {
                        required: 'Field required*',
                      })}
                      sx={{
                        ...customStyles.inputField,
                        borderColor: errors.challenges ? 'error.main' : 'divider'
                      }}
                    />
                    {errors.challenges && (
                      <Typography 
                        sx={{ 
                          color: 'error.main', 
                          mt: 0.5, 
                          fontSize: '0.75rem' 
                        }}
                      >
                        {errors?.challenges?.message}
                      </Typography>
                    )}
                  </Box>

                  <Box>
                    <Typography 
                      variant="subtitle2" 
                      sx={customStyles.typography.subtitle}
                    >
                      Solutions you are looking for
                    </Typography>
                    <CustomFormInput
                      multiline
                      rows={3}
                      {...register('solutionslookingfor', {
                        required: 'Field required*',
                      })}
                      sx={{
                        ...customStyles.inputField,
                        borderColor: errors.solutionslookingfor ? 'error.main' : 'divider'
                      }}
                    />
                    {errors.solutionslookingfor && (
                      <Typography 
                        sx={{ 
                          color: 'error.main', 
                          mt: 0.5, 
                          fontSize: '0.75rem' 
                        }}
                      >
                        {errors?.solutionslookingfor?.message}
                      </Typography>
                    )}
                  </Box>

                  <Typography
                    variant="subtitle2"
                    gutterBottom={false}
                    sx={{
                      ...customStyles.typography.subtitle,
                      mb: 1
                    }}
                  >
                    Have you been using Push Notification Tool?
                  </Typography>

                  <FormControl fullWidth required margin="dense">
                    <SignupSelect
                      value={questionDetails?.everusedpushnotification || ''}
                      onChange={handleChange}
                      name="everusedpushnotification"
                      displayEmpty
                      sx={customStyles.select}
                    >
                      <MenuItem value="Yes" sx={{ '&:hover': { backgroundColor: 'rgba(8, 255, 217, 0.1)' } }}>
                        Yes
                      </MenuItem>
                      <MenuItem value="No" sx={{ '&:hover': { backgroundColor: 'rgba(8, 255, 217, 0.1)' } }}>
                        No
                      </MenuItem>
                    </SignupSelect>
                  </FormControl>

                  {questionDetails?.everusedpushnotification === 'Yes' && (
                    <>
                      <Box my={2}>
                        <Typography 
                          variant="subtitle2" 
                          sx={{
                            ...customStyles.typography.subtitle,
                            mb: 1
                          }}
                        >
                          If Yes, which one?
                        </Typography>
                        <OuterInputField
                          name={'olderserviceprovider'}
                          value={questionDetails?.olderserviceprovider}
                          onChange={(e) => {
                            const newData = { ...questionDetails };
                            delete newData.whynot;
                            setQuestionDetails({
                              ...newData,
                              olderserviceprovider: e.target.value,
                            });
                          }}
                        />
                      </Box>
                      <Box mt={2}>
                        <Typography 
                          variant="subtitle2" 
                          sx={{
                            ...customStyles.typography.subtitle,
                            mb: 1
                          }}
                        >
                          Why you're switching from others to here?
                        </Typography>
                        <OuterInputField
                          name={'reasonforswitching'}
                          multiline={true}
                          value={questionDetails?.reasonforswitching}
                          onChange={(e) => {
                            setQuestionDetails({
                              ...questionDetails,
                              reasonforswitching: e.target.value,
                            });
                          }}
                        />
                      </Box>
                    </>
                  )}
                  {questionDetails?.everusedpushnotification === 'No' && (
                    <Box my={2}>
                      <Typography 
                        variant="subtitle2" 
                        sx={{
                          ...customStyles.typography.subtitle,
                          mb: 1
                        }}
                      >
                        If No, Why?
                      </Typography>
                      <OuterInputField
                        name={'whynot'}
                        multiline={true}
                        value={questionDetails?.whynot}
                        onChange={(e) => {
                          const newData = { ...questionDetails };
                          delete newData.olderserviceprovider;
                          delete newData.reasonforswitching;
                          setQuestionDetails({
                            ...newData,
                            whynot: e.target.value,
                          });
                        }}
                      />
                    </Box>
                  )}
                </Stack>

                <Box textAlign={'center'} mt={3}>
                  <RoundedButton
                    title={'Start Setup'}
                    onClick={handleSubmit(handleClick)}
                    sx={{
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-3px)',
                        boxShadow: '0 4px 10px rgba(8, 255, 217, 0.3)'
                      }
                    }}
                  />
                </Box>
              </form>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutCustomer;
