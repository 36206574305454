import React, { useEffect, useState } from 'react';
import { Box, Button, Slide, Stack, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
  DeleteConnection,
  FetchAllConnnection,
  FetchAllCustomPrompt,
  FetchAllTemplate,
  UpdateConnection,
} from 'Api/Api';
import ListView from 'components/ListView';
import { DeleteModal } from 'components/Modals';
import { ContainerStyle, MainDashboard } from 'components/Style';
import { ConnectionTableColumns } from 'constants/appConstant';
import EditConnectModal from './EditConnectModal';
import { protectedRoutes } from 'constants/appRoutes';
import NoRecord from 'components/NoRecord';
import EnlargeCustomModal from './EnlargeCustomModal';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ConnectDashboard = () => {
  const [connectionData, setConnectionData] = useState([]);
  const [searchTerms, setSearchTerms] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editId, setEditId] = useState(0);
  const [deleteId, setDeleteId] = useState(0);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);
  const [isDetail, setIsDetail] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tempItem, setTempItem] = useState({});
  const [openEnlarge, setEnlarge] = useState(false);
  const [welcomeTemplate, setWelcomeTemplate] = useState(null);
  const [customPrompt, setCustomPrompt] = useState(null);

  const navigate = useNavigate();

  const handleCreateModal = (e) => {
    if (connectionData.length >= 1) {
      toast.error('You can not create more than one Connection');
    } else {
      navigate(protectedRoutes.createConnect);
    }
  };

  const helperDocs = () => {
    navigate(protectedRoutes.createConnect);
  };

  const handleEnlargeModal = (item) => {
    setTempItem(item);
    setEnlarge(true);
  };

  const handleEditModel = (recordId, isDetail) => {
    setEditModalOpen(true);
    setEditId(recordId);
    setIsDetail(isDetail);
  };

  const handleDeleteModel = (recordId) => {
    setDeleteModalOpen(true);
    setDeleteId(recordId);
  };

  const onClose = () => {
    setEditModalOpen(false);
    setDeleteModalOpen(false);
  };

  const closeEnlargeModal = () => {
    setEnlarge(false);
  };

  async function fetch() {
    try {
      setInitialLoading(true);
      let connectionId = localStorage.getItem('connectionId') || undefined;
      let response = await FetchAllConnnection();
      if (response?.data?.status === true) {
        const firstConnection = response?.data?.data?.[0];
        connectionId = firstConnection.id;
        localStorage.setItem('connectionId', connectionId);
        setConnectionData(response?.data?.data);
        setInitialLoading(false);
      }
    } catch (error) {
      setInitialLoading(false);
      toast.error(error.response.data.message);
    }
  }

  async function deleteConnect(recordId) {
    try {
      const response = await DeleteConnection(recordId);
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        fetch();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  async function updateStatus(item) {
    setLoading((prevLoading) => ({
      ...prevLoading,
      [item.id]: true,
    }));
    if (item?.status === 'Active') {
      const response = await UpdateConnection({
        id: item.id,
        name: item.name,
        status: item.status === 'Active' ? 'Inactive' : '',
      });
      if (response?.status === 200) {
        fetch();
      }
      setLoading((prevLoading) => ({
        ...prevLoading,
        [item.id]: false,
      }));
    }
  }

  async function WelcomeTemplateFetch() {
    try {
      let response = await FetchAllTemplate();
      if (response?.data?.status === true) {
        let alltemplates = response?.data?.data;
        const welcomeTemplate = alltemplates.find(
          (template) => template.welcomeTemplate === true
        );
        if (welcomeTemplate) {
          setWelcomeTemplate(welcomeTemplate);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  async function CustomPromptFetch() {
    try {
      let response = await FetchAllCustomPrompt();
      if (response?.data?.data !== null) {
        const { connection, ...dataWithoutConnection } = response?.data?.data;
        setCustomPrompt(dataWithoutConnection);
      } else {
        setCustomPrompt(null);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  useEffect(() => {
    fetch();
    onClose();
  }, []);

  useEffect(() => {
    if (!initialLoading) {
      WelcomeTemplateFetch();
      CustomPromptFetch();
    }
  }, [initialLoading]);

  useEffect(() => {
    let data = connectionData.find((item) => item.id === tempItem.id);
    if (data) {
      setTempItem(data);
    }
  }, [connectionData]);

  return (
    <MainDashboard>
      <Box sx={ContainerStyle.container}>
        <Stack direction="row" sx={ContainerStyle.topBar}>
          <Typography variant="h5" sx={ContainerStyle.heading}>
            Connections
          </Typography>

          {connectionData.length < 0 ? (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddToPhotosIcon />}
              onClick={handleCreateModal}
              size="large"
              sx={{ width: '150px', marginLeft: '16px', marginBottom: '6px' }}
            >
              Create
            </Button>
          ) : (
            <Button
              size="large"
              variant="contained"
              onClick={helperDocs}
              sx={{ width: '150px', marginLeft: '16px', marginBottom: '6px' }}
            >
              Help&nbsp;Docs
            </Button>
          )}
        </Stack>
        <Box sx={ContainerStyle.listView}>
          <ListView
            tableData={connectionData}
            rowData={ConnectionTableColumns}
            updateStatus={updateStatus}
            handleEditModel={handleEditModel}
            initialLoading={initialLoading}
            loading={loading}
            handleDeleteModel={handleDeleteModel}
            icons={['edit', 'delete', 'detail']}
            sortingIndex={['name', 'createdTime', 'modifiedTime']}
            searchTerms={searchTerms}
            setSearchTerms={setSearchTerms}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            handleEnlargeModal={handleEnlargeModal}
            noRecordComponent={
              <NoRecord
                type="callback"
                moduleName="Connection"
                onAction={handleCreateModal}
              />
            }
          />
        </Box>

        <EnlargeCustomModal
          onCloseModal={closeEnlargeModal}
          open={openEnlarge}
          item={tempItem}
          welcomeTemplate={welcomeTemplate}
          refresh={fetch}
          refreshTemplate={WelcomeTemplateFetch}
          refreshCustom={CustomPromptFetch}
          customPrompt={customPrompt}
          setCustomPrompts={setCustomPrompt}
          setWelcomeTemplates={setWelcomeTemplate}
          initialLoading={initialLoading}
        />

        <EditConnectModal
          TransitionComponent={Transition}
          open={editModalOpen}
          onClose={onClose}
          itemId={editId}
          isDetail={isDetail}
          refresh={fetch}
        />
        <DeleteModal
          open={deleteModalOpen}
          close={onClose}
          placeholder="Connection"
          deleteFunction={() => deleteConnect(deleteId)}
        />
      </Box>
    </MainDashboard>
  );
};

export default ConnectDashboard;
