import React, { useEffect, useState } from 'react';
import { Box, Card, Switch, Tab, TextField, Typography } from '@mui/material';
import { UpdateCustomPrompt } from 'Api/Api';
import toast from 'react-hot-toast';
import { Loading } from 'components/Loading';
import { SideDrawer } from 'components/SideDrawer';
import { InnerTextField } from 'components/InputFields';
import { TemplateStyles } from 'pages/protected/Templates/Style';
import {
  DesktopPosition,
  MobilePosition,
  Time,
} from '../customPrompt/constant';
import { CustomSelect } from 'components/CustomSelect';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import logoSrc from 'assets/Images/Common/letsnotify.png';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import Tooltip from '@mui/material/Tooltip';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

const EditCustomPromptModal = ({
  open,
  onClose,
  itemId,
  refresh,
  refreshCustom,
}) => {
  const [customPrompt, setCustomPrompt] = useState(itemId || {});
  const [image, setImage] = useState({
    logo: '',
  });
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState('1');
  const [isPreview, setIsPreview] = useState(false);
  const [imagePreview, setImagePreview] = useState(false);

  const [errors, setErrors] = useState({
    title: false,
    description: false,
    allowButtonText: false,
    hidePromptFrequency: false,
    showPromptFrequency: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Handle the update of customPrompt
    setCustomPrompt((prevState) => {
      if (name in prevState.data.styles) {
        return {
          ...prevState,
          data: {
            ...prevState.data,
            styles: {
              ...prevState.data.styles,
              [name]: value,
            },
          },
        };
      } else {
        return {
          ...prevState,
          data: {
            ...prevState.data,
            [name]: value,
          },
        };
      }
    });

    // Only check for empty values if they are strings
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: typeof value === 'string' && value.trim() === '',
    }));
  };

  const handleToggle = (e) => {
    const { name } = e.target;
    if (name === 'status') {
      const newStatus =
        customPrompt?.status === 'Active' ? 'Inactive' : 'Active';
      setCustomPrompt((prevState) => ({
        ...prevState,
        status: newStatus,
      }));
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCustomPrompt((prev) => ({
        ...prev,
        iconImage: file,
      }));
      setImage((prev) => ({
        ...prev,
        logo: URL.createObjectURL(file),
      }));
    }
  };

  const handletabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {
      //   title: customPrompt?.data?.title.trim() === '',
      //   description: customPrompt?.data?.description.trim() === '',
      //   allowButtonText:
      //     customPrompt?.data?.styles?.allowButtonText.trim() === '',
      //   hidePromptFrequency:
      //     customPrompt?.data?.styles?.hidePromptFrequency.trim() === '',
      //   showPromptFrequency:
      //     customPrompt?.data?.styles?.showPromptFrequency.trim() === '',
    };
    setErrors(newErrors);
    if (Object.values(newErrors).every((error) => !error)) {
      try {
        setLoading(true);
        const { iconImageURL, ...customPromptData } = customPrompt;
        const response = await UpdateCustomPrompt(
          customPrompt?.id,
          customPromptData
        );
        if (response?.data?.status === true) {
          toast.success(response?.data?.message);
          refreshCustom();
          refresh();
          onClose();
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message || 'An error occured');
      }
    }
  };

  const handleImagePreview = () => {
    setImagePreview(!imagePreview);
  };

  useEffect(() => {
    if (itemId) {
      const { iconImageURL, ...restItemId } = itemId;
      setCustomPrompt((prev) => ({ ...prev, ...restItemId }));
      if (iconImageURL) {
        setImage({ logo: iconImageURL });
      }
    }
  }, [itemId]);

  // if (loading) {
  //   return (
  //     <SkeletonLoading value={Object.keys(itemId).length}/>
  //   );
  // }
  return (
    <>
      <Loading state={loading} />
      <SideDrawer
        open={open}
        onClose={onClose}
        title={'Edit Custom Prompt'}
        handleSubmit={handleSubmit}
        EyeIcon={true}
        isPreview={isPreview}
        setIsPreview={setIsPreview}
      >
        {!isPreview && (
          <Box>
            <Box width={'90%'}>
              <Box>
                <InnerTextField
                  required
                  margin="dense"
                  name="title"
                  label="Title"
                  value={customPrompt?.data?.title}
                  onChange={handleChange}
                />
              </Box>

              <Box>
                <InnerTextField
                  required
                  name="description"
                  label="Description"
                  value={customPrompt?.data?.description}
                  onChange={handleChange}
                />
              </Box>

              <Box marginY={1}>
                <Typography variant="black_4">Status</Typography>
                <Switch
                  name="status"
                  size="large"
                  checked={customPrompt?.status === 'Active'}
                  onChange={handleToggle}
                />
              </Box>
              <Box marginY={2}>
                <Typography variant="subtitle1" fontWeight={500} mb={1}>
                  Icon Image
                </Typography>
                {image.logo ? (
                  <Box 
                    sx={{
                      position: 'relative',
                      width: '100%',
                      maxWidth: { xs: '250px', sm: '300px', md: '400px' },
                      aspectRatio: '16/9',
                      borderRadius: 2,
                      overflow: 'hidden',
                      boxShadow: 2,
                      transition: 'transform 0.3s',
                      '&:hover': {
                        transform: 'scale(1.02)',
                      }
                    }}
                  >
                    <img 
                      src={image.logo} 
                      alt="Icon Image" 
                      style={{
                        width: '100%', 
                        height: '100%', 
                        objectFit: 'cover'
                      }} 
                    />
                    <Tooltip title="View Full Image">
                      <Button
                        onClick={handleImagePreview}
                        sx={{
                          position: 'absolute',
                          top: 8,
                          right: 8,
                          backgroundColor: 'rgba(255,255,255,0.7)',
                          minWidth: 'auto',
                          p: 1,
                          borderRadius: '50%',
                          '&:hover': {
                            backgroundColor: 'rgba(255,255,255,0.9)',
                          }
                        }}
                      >
                        <ZoomInIcon fontSize="small" />
                      </Button>
                    </Tooltip>
                  </Box>
                ) : (
                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                  >
                    No image uploaded
                  </Typography>
                )}
                <input 
                  type="file" 
                  accept="image/*" 
                  onChange={handleFileChange} 
                  style={{ marginTop: '10px' }}
                />
              </Box>
              <br />

              <Box>
                <Typography sx={{ fontSize: '18px' }} variant="black_p">
                  Allow Button
                </Typography>

                <InnerTextField
                  required
                  name="allowButtonText"
                  label="Text"
                  value={customPrompt?.data?.styles?.allowButtonText}
                  onChange={handleChange}
                />
              </Box>
              <Box>
                <InnerTextField
                  required
                  name="allowButtonBackgroundColor"
                  label="Background Color"
                  colorSelection
                  value={customPrompt?.data?.styles?.allowButtonBackgroundColor}
                  onChange={handleChange}
                />
              </Box>
              <Box>
                <InnerTextField
                  required
                  name="allowButtonTextColor"
                  label="Text Color"
                  colorSelection
                  value={customPrompt?.data?.styles?.allowButtonTextColor}
                  onChange={handleChange}
                />
              </Box>
              <Box>
                <InnerTextField
                  required
                  label="Later Button Text"
                  name="laterButtonText"
                  value={customPrompt?.data?.styles?.laterButtonText}
                  onChange={handleChange}
                />
              </Box>

              <Box my={2}>
                <Typography sx={{ fontSize: '18px' }} variant="subtitle3">
                  Opt-in Timings
                </Typography>
              </Box>
              <Box>
                <CustomSelect
                  label="Mobile (In Sec)"
                  name="mobileTiming"
                  fullWidth
                  options={Time}
                  value={customPrompt?.data?.styles?.mobileTiming}
                  defaultValue={customPrompt?.data?.styles?.mobileTiming}
                  onChange={handleChange}
                />
              </Box>

              <Box my={2}>
                <Typography sx={{ fontSize: '18px' }} variant="subtitle3">
                  Opt-in Timings
                </Typography>
              </Box>
              <Box>
                <CustomSelect
                  label="Desktop (In Sec)"
                  name="desktopTiming"
                  fullWidth
                  options={Time}
                  value={customPrompt?.data?.styles?.desktopTiming}
                  defaultValue={customPrompt?.data?.styles?.desktopTiming}
                  onChange={handleChange}
                />
              </Box>
              <Box mt={1}>
                <InnerTextField
                  required
                  name="hidePromptFrequency"
                  label="Hide Prompt Frequency"
                  value={customPrompt?.data?.styles?.hidePromptFrequency}
                  onChange={handleChange}
                />
              </Box>
              <Box>
                <InnerTextField
                  required
                  name="showPromptFrequency"
                  label="Show Prompt Frequency"
                  value={customPrompt?.data?.styles?.showPromptFrequency}
                  onChange={handleChange}
                />
              </Box>

              <Box my={2}>
                <Typography sx={{ fontSize: '18px' }} variant="subtitle3">
                  Position
                </Typography>
              </Box>
              <Box>
                <CustomSelect
                  label="Desktop"
                  name="desktopPosition"
                  fullWidth
                  value={customPrompt?.data?.styles?.desktopPosition}
                  options={DesktopPosition}
                  defaultValue={customPrompt?.data?.styles?.desktopPosition}
                  onChange={handleChange}
                />
              </Box>
              <Box mt={3}>
                <CustomSelect
                  label="Mobile"
                  name="mobilePosition"
                  fullWidth
                  value={customPrompt?.data?.styles?.mobilePosition}
                  options={MobilePosition}
                  defaultValue={customPrompt?.data?.styles?.mobilePosition}
                  onChange={handleChange}
                />
              </Box>
            </Box>
          </Box>
        )}
        {isPreview && (
          <Box sx={{ height: '100%', width: '100%' }}>
            <Box
              sx={{
                height: '60px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 2,
                borderBottom: '1.8px solid rgba(241, 242, 247, 1)',
                backgroundColor: 'background.paper',
                boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <VisibilityIcon
                  sx={{
                    color: 'primary.main',
                    fontSize: 24,
                    mr: 1,
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    color: 'text.primary',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  Custom Prompt Preview
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Tooltip title="Device Comparison">
                  <IconButton
                    size="small"
                    sx={{
                      color: tabValue === '1' ? 'primary.main' : 'text.secondary',
                      backgroundColor:
                        tabValue === '1' ? 'primary.light' : 'transparent',
                    }}
                    onClick={() => setTabValue('1')}
                  >
                    <DesktopWindowsIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Device Comparison">
                  <IconButton
                    size="small"
                    sx={{
                      color: tabValue === '2' ? 'primary.main' : 'text.secondary',
                      backgroundColor:
                        tabValue === '2' ? 'primary.light' : 'transparent',
                    }}
                    onClick={() => setTabValue('2')}
                  >
                    <PhoneIphoneIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box
              sx={{
                height: 'calc(100% - 60px)',
              }}
            >
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    onChange={handletabChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      label="Desktop"
                      sx={{
                        fontWeight: '600',
                        fontSize: '16px',
                      }}
                      value="1"
                    />
                    <Tab
                      label="Mobile"
                      sx={{
                        fontWeight: '600',
                        fontSize: '16px',
                      }}
                      value="2"
                    />
                  </TabList>
                </Box>
                <TabPanel sx={{ height: '60vh' }} value="1">
                  <Box
                    sx={{
                      mx: 'auto',
                      width: '90%',
                      borderRadius: 4,
                      height: '100%',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                      border: '1.8px solid rgba(0,0,0,.12)',
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        height: '40px',
                        borderBottom: '1.8px solid rgba(0,0,0,.12)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        pl: 2,
                      }}
                    >
                      {['#EC6A5E', '#F5BF4F', '#61C554'].map((color, index) => (
                        <Box
                          key={index}
                          sx={{
                            height: 15,
                            width: 15,
                            borderRadius: '50%',
                            backgroundColor: color,
                          }}
                        ></Box>
                      ))}
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        height: 'calc(100% - 40px)',
                        backgroundColor: '#f6f6f6',
                        display: 'flex',
                        p: 2,
                        justifyContent: 'center',
                        ...getPositionStyles(customPrompt?.data?.description),
                      }}
                    >
                      <Card sx={{ width: '200px', height: '80px' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            width: '100%',
                            height: '60%',
                            px: 1,
                          }}
                        >
                          {image.logo && (
                            <img
                              src={image.logo}
                              alt="Logo"
                              style={{
                                width: '50px',
                                height: '50px',
                                overflow: 'hidden',
                                objectFit: 'contain',
                              }}
                            />
                          )}
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={'100%'}
                          >
                            <Typography
                              variant="black_p"
                              sx={{ fontSize: '12px', fontWeight: 500 }}
                            >
                              {customPrompt?.data?.title}
                            </Typography>
                            <Typography
                              variant="black_p"
                              sx={{ fontSize: '8px' }}
                            >
                              {customPrompt?.data?.description}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'right',
                            width: '100%',
                            fontSize: 9,
                            gap: 0.5,
                            px: 1,
                            height: '14px',
                          }}
                        >
                          {customPrompt?.data?.styles?.laterButtonText && (
                            <Box
                              sx={{
                                borderColor: 'gray',
                                borderWidth: '.5px',
                                color: 'gray',
                                p: '1px 2px',
                                borderRadius: 1,
                              }}
                            >
                              {customPrompt?.data?.styles?.laterButtonText}
                            </Box>
                          )}
                          {customPrompt?.data?.styles?.allowButtonText && (
                            <Box
                              sx={{
                                bgcolor:
                                  customPrompt?.data?.styles
                                    ?.allowButtonBackgroundColor,
                                color:
                                  customPrompt?.data?.styles
                                    ?.allowButtonTextColor,
                                p: '1px 3px',
                                borderRadius: 1,
                              }}
                            >
                              {customPrompt?.data?.styles?.allowButtonText}
                            </Box>
                          )}
                        </Box>
                        <Box
                          display={'flex'}
                          width={'100%'}
                          justifyContent={'end'}
                          alignItems={'center'}
                          px={1}
                          gap={1}
                        >
                          <p className="text-[8px]">Powered by</p>
                          <img className="h-3" src={logoSrc} />
                        </Box>
                      </Card>
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel sx={{ height: '70vh' }} value="2">
                  <Box
                    sx={{
                      position: 'relative',
                      mx: 'auto',
                      width: '50%',
                      borderRadius: 4,
                      height: '100%',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                      border: '1.8px solid rgba(0,0,0,.12)',
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        height: '40px',
                        borderBottom: '1.8px solid rgba(0,0,0,.12)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        pl: 2,
                      }}
                    >
                      {['#EC6A5E', '#F5BF4F', '#61C554'].map((color, index) => (
                        <Box
                          key={index}
                          sx={{
                            height: 15,
                            width: 15,
                            borderRadius: '50%',
                            backgroundColor: color,
                          }}
                        ></Box>
                      ))}
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        height: 'calc(100% - 40px)',
                        backgroundColor: '#f6f6f6',
                        display: 'flex',
                        justifyContent: 'center',
                        ...getPositionStyles(
                          customPrompt?.data?.styles?.mobilePosition
                        ),
                      }}
                    >
                      <Card sx={{ width: '200px', height: '80px', mt: 2 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            width: '100%',
                            height: '60%',
                            px: 1,
                          }}
                        >
                          {image.logo && (
                            <img
                              src={image.logo}
                              alt="Logo"
                              style={{
                                width: '50px',
                                height: '50px',
                                overflow: 'hidden',
                                objectFit: 'contain',
                              }}
                            />
                          )}
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={'100%'}
                          >
                            <Typography
                              variant="black_p"
                              sx={{ fontSize: '12px', fontWeight: 500 }}
                            >
                              {customPrompt?.data?.title}
                            </Typography>
                            <Typography
                              variant="black_p"
                              sx={{ fontSize: '8px' }}
                            >
                              {customPrompt?.data?.description}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'right',
                            width: '100%',
                            fontSize: 9,
                            gap: 0.5,
                            px: 1,
                            height: '14px',
                          }}
                        >
                          {customPrompt?.data?.styles?.laterButtonText && (
                            <Box
                              sx={{
                                borderColor: 'gray',
                                borderWidth: '.5px',
                                color: 'gray',
                                p: '1px 2px',
                                borderRadius: 1,
                              }}
                            >
                              {customPrompt?.data?.styles?.laterButtonText}
                            </Box>
                          )}
                          {customPrompt?.data?.styles?.allowButtonText && (
                            <Box
                              sx={{
                                bgcolor:
                                  customPrompt?.data?.styles
                                    ?.allowButtonBackgroundColor,
                                color:
                                  customPrompt?.data?.styles
                                    ?.allowButtonTextColor,
                                p: '1px 3px',
                                borderRadius: 1,
                              }}
                            >
                              {customPrompt?.data?.styles?.allowButtonText}
                            </Box>
                          )}
                        </Box>
                        <Box
                          display={'flex'}
                          width={'100%'}
                          justifyContent={'end'}
                          alignItems={'center'}
                          px={1}
                          gap={1}
                        >
                          <p className="text-[8px]">Powered by</p>
                          <img className="h-3" src={logoSrc} />
                        </Box>
                      </Card>
                    </Box>
                  </Box>
                </TabPanel>
              </TabContext>
            </Box>
          </Box>
        )}
      </SideDrawer>

      {/* Full Image Preview Modal */}
      {imagePreview && image.logo && (
        <Modal
          open={imagePreview}
          onClose={handleImagePreview}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box 
            sx={{
              maxWidth: '90%',
              maxHeight: '90%',
              outline: 'none',
              position: 'relative',
            }}
          >
            <IconButton
              onClick={handleImagePreview}
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                backgroundColor: 'rgba(0,0,0,0.5)',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.7)',
                }
              }}
            >
              <CloseIcon />
            </IconButton>
            <img 
              src={image.logo} 
              alt="Full Image" 
              style={{
                width: '100%', 
                height: '100%', 
                objectFit: 'contain',
                maxWidth: '100%',
                maxHeight: '100%',
              }} 
            />
          </Box>
        </Modal>
      )}
    </>
  );
};

export default EditCustomPromptModal;

const getPositionStyles = (position) => {
  switch (position) {
    case 'center':
      return {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      };
    case 'top':
      return { display: 'flex', justifyContent: 'center' };
    case 'top-left':
      return { display: 'flex', justifyContent: 'start', alignItems: 'start' };
    case 'top-right':
      return { display: 'flex', justifyContent: 'end', alignItems: 'start' };
    case 'bottom-left':
      return { display: 'flex', justifyContent: 'start', alignItems: 'end' };
    case 'bottom-right':
      return { display: 'flex', justifyContent: 'end', alignItems: 'end' };
    default:
      return {};
  }
};
