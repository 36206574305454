import React, { useState } from "react";
import { GripVertical } from 'lucide-react';

const EditableTextInput = ({ question, onChange }) => {
    const [editableQuestion, setEditableQuestion] = useState(question || "Your Question?");

    return (
        <div className="p-6 border rounded-lg bg-white shadow-md hover:shadow-lg transition-shadow">
            <div className="flex items-center mb-4">
                <GripVertical className="w-6 h-6 text-gray-400 cursor-move mr-2" />
                <input
                    type="text"
                    value={editableQuestion}
                    onChange={(e) => {
                        setEditableQuestion(e.target.value);
                        onChange(e.target.value);
                    }}
                    className="w-full text-lg font-semibold px-2 py-1 border-b-2 border-transparent hover:border-gray-200 focus:border-blue-500 focus:outline-none transition-colors"
                    placeholder="Enter your question"
                />
            </div>

            <textarea
                disabled
                placeholder="User's response will appear here..."
                className="w-full h-24 p-3 border rounded-md bg-gray-50 cursor-not-allowed resize-none"
            ></textarea>
        </div>
    );
};

export default EditableTextInput;