import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { protectedRoutes } from 'constants/appRoutes';
import logoSrc from 'assets/Images/Common/logo.png';
import { SideMenuItems, ProfileMenuItems } from '.././constants/appConstant';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import theme from 'styles/app.theme';
import { clearAuthDetails } from '../redux/reducers/authReducer';
import searchIcon from '../assets/Icons/SideBar/search.png';
import { GetUserDetails } from 'Api/Api';
import toast from 'react-hot-toast';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';

const Drawer = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[4],
  borderRight: `1px solid ${theme.palette.divider}`,
  transition: 'all 0.3s ease',
}));

const SidebarListItem = styled(ListItemButton)(({ theme, selected }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  margin: theme.spacing(0.5, 0),
  height: 48,
  transition: 'all 0.2s ease',
  color: '#B2D8D2',
  '&:hover': {
    backgroundColor: 'rgba(7, 130, 111, 0.2)', 
    transform: 'translateX(4px)',
  },
  ...(selected && {
    backgroundColor: 'rgba(7, 130, 111, 0.4)', 
    color: '#49FFE3', 
    '&:hover': {
      backgroundColor: 'rgba(7, 130, 111, 0.5)', 
    },
  }),
}));

const RenderListItem = ({
  eachItem,
  index,
  openIndex,
  handleClick,
  isActive,
  navigate,
}) => {
  const theme = useTheme();
  const IconComponent =
    isActive(eachItem.link) ||
    eachItem.child?.some((child) => isActive(child.link))
      ? eachItem.icon.active
      : eachItem.icon.inactive;

  const isItemActive = 
    isActive(eachItem.link) ||
    eachItem.child?.some((child) => isActive(child.link));

  const handleItemClick = () => {
    if (!eachItem.comingSoon) {
      handleClick(index);
    }
  };

  return (
    <React.Fragment key={index}>
      <ListItem 
        disablePadding 
        sx={{ 
          position: 'relative',
          width: '100%',
        }}
      >
        {isItemActive && (
          <Box
            sx={{
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              width: '4px',
              height: '50%',
              backgroundColor: '#49FFE3',
              borderRadius: 1,
              marginRight: theme.spacing(1),
            }}
          />
        )}
        <SidebarListItem
          selected={isItemActive}
          onClick={handleItemClick}
          sx={{
            width: 'calc(100% - 4px - 8px)',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: theme.spacing(2),
            px: theme.spacing(2),
            position: 'relative',
            fontSize: '1.1rem', // Increased font size
          }}
        >
          <ListItemIcon 
            sx={{ 
              minWidth: 'auto', 
              mr: theme.spacing(2),
              color: isItemActive 
                ? '#49FFE3'  
                : '#B2D8D2'  
            }}
          >
            <IconComponent />
          </ListItemIcon>
          <ListItemText 
            primary={eachItem.name} 
            primaryTypographyProps={{
              variant: 'body2',
              sx: {
                color: isItemActive 
                  ? '#49FFE3'   
                  : '#B2D8D2',  
                fontWeight: isItemActive ? 600 : 400,
                fontSize: {
                  xs: '1.1rem', // Increased font size
                  sm: '1.2rem', // Increased font size
                  md: '1.1rem' // Increased font size
                }
              }
            }} 
          />
          {eachItem.comingSoon && (
            <Box
              sx={{
                position: 'absolute',
                right: theme.spacing(2),
                top: '50%',
                transform: 'translateY(-50%)',
                backgroundColor: '#49FFE3',
                color: '#07826F',
                borderRadius: '5px',
                padding: '2px 5px',
                fontSize: '8px',
                fontWeight: '700',
                textTransform: 'uppercase',
                letterSpacing: '0.5px',
              }}
            >
              Coming Soon
            </Box>
          )}
          {eachItem.child && (
            openIndex === index ? (
              <ExpandLess 
                sx={{ 
                  color: isItemActive 
                    ? '#49FFE3'   
                    : '#C0C0C0'   
                }} 
              />
            ) : (
              <ExpandMore 
                sx={{ 
                  color: isItemActive 
                    ? '#49FFE3'   
                    : '#C0C0C0'   
                }} 
              />
            )
          )}
        </SidebarListItem>
      </ListItem>
      {eachItem.child && (
        <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {eachItem.child.map((childItem, childIndex) => {
              const handleChildClick = () => {
                if (!childItem.comingSoon) {
                  navigate(childItem.link);
                }
              };

              return (
                <ListItem 
                  key={childIndex} 
                  disablePadding 
                  sx={{ 
                    position: 'relative',
                    width: '100%',
                  }}
                >
                  {isActive(childItem.link) && (
                    <Box
                      sx={{
                        position: 'absolute',
                        right: 0,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: '2px',
                        height: '50%',
                        backgroundColor: '#49FFE3',
                        borderRadius: 1,
                        marginRight: theme.spacing(1),
                      }}
                    />
                  )}
                  <ListItemButton
                    sx={{ 
                      pl: theme.spacing(4),
                      py: theme.spacing(1),
                      width: 'calc(100% - 2px - 8px)',
                      color: '#B2D8D2',
                      backgroundColor: isActive(childItem.link) 
                        ? 'rgba(7, 130, 111, 0.4)' 
                        : 'transparent',
                      '&:hover': {
                        backgroundColor: isActive(childItem.link) 
                          ? 'rgba(7, 130, 111, 0.5)' 
                          : 'rgba(255,255,255,0.1)',
                      }
                    }}
                    onClick={handleChildClick}
                  >
                    <ListItemText 
                      primary={childItem.name} 
                      primaryTypographyProps={{
                        variant: 'body2',
                        sx: {
                          color: isActive(childItem.link)
                            ? '#fff'
                            : '#B2D8D2',  
                          fontSize: {
                            xs: '1.1rem', // Increased font size
                            sm: '1.2rem', // Increased font size
                            md: '1.1rem' // Increased font size
                          }
                        }
                      }}
                    />
                    {childItem.comingSoon && (
                      <Box
                        sx={{
                          height: '50%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'flex-end',
                          position: 'absolute',
                          right: theme.spacing(2),
                          top: '50%',
                          transform: 'translateY(-50%)',
                          backgroundColor: '#49FFE3',
                          color: '#07826F',
                          borderRadius: '5px',
                          padding: '2px 5px',
                          fontSize: '8px',
                          fontWeight: '700',
                          textTransform: 'uppercase',
                          letterSpacing: '0.5px',
                        }}
                      >
                        Coming Soon
                      </Box>
                    )}
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  );
};

export const AppSidebar = ({ toggleSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const [userDetails, setUserDetails] = useState({});
  const [openIndex, setOpenIndex] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState(SideMenuItems);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);

  const name = userDetails?.userName || 'Unknown';
  const email = userDetails?.email;
  const firstLetter = name.charAt(0);
  const profileImageUrl = userDetails?.profileImageURL;

  const handlePopover = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPopover(true);
  };

  const handleClose = () => {
    setOpenPopover(false);
    setAnchorEl(null);
  };

  const handleFeatureRequest = () => {
    window.open('https://forms.gle/vqhSQvrme3truhPA6', '_blank');
  };

  const handleClick = (index) => {
    const path = SideMenuItems[index]?.link;
    if (path) {
      handleNavigate(path);
      if (toggleSidebar) toggleSidebar();
      setOpenIndex(null);
    } else {
      setOpenIndex(openIndex === index ? null : index);
    }
  };

  const fetch = async () => {
    try {
      const response = await GetUserDetails();
      if (response?.data?.status === true) {
        setUserDetails(response?.data?.data);
      }
    } catch (error) {
      toast.error(error?.response?.data.message);
    }
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleNavigate = (path) => {
    navigate(path);
    clearSearch();
    if (toggleSidebar) toggleSidebar();
  };

  const handleLogout = () => {
    localStorage.clear('token');
    dispatch(clearAuthDetails({}));
    navigate('/login');
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    filterItems(e.target.value);
  };

  const clearSearch = () => {
    setSearchQuery('');
    setFilteredItems(SideMenuItems);
    setSearchOpen(false);
  };

  const filterItems = (query) => {
    if (!query) {
      setFilteredItems(SideMenuItems);
      return;
    }

    const lowerQuery = query.toLowerCase();
    const filtered = [];

    SideMenuItems.forEach((item) => {
      const filteredChildren = item?.child?.filter((child) =>
        child?.name?.toLowerCase().startsWith(lowerQuery)
      );

      if (filteredChildren?.length) {
        filteredChildren.forEach((child) => {
          filtered.push({
            ...child,
            parentName: item.name,
            icon: child.icon,
          });
        });
      } else if (item.name.toLowerCase().startsWith(lowerQuery)) {
        filtered.push({
          ...item,
          parentName: null,
          icon: item.icon,
        });
      }
    });

    setFilteredItems(filtered);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Drawer
      sx={{ position: 'relative', height: '100%', backgroundColor: '#033A32' }}
    >
      <Button
        sx={{
          display: { xs: 'block', md: 'none' },
          position: 'absolute',
          top: 27,
          right: 0,
          zIndex: 10,
          cursor: 'pointer',
          color: 'whitesmoke',
        }}
        onClick={toggleSidebar}
      >
        <CloseIcon sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }} />
      </Button>

      <IconButton
        sx={{
          display: 'block',
          position: 'absolute',
          top: { xs: 22, sm: 22, md: 22 },
          right: { xs: 40, sm: 50, md: 5 },
          zIndex: 10,
          cursor: 'pointer',
          color: 'whitesmoke',
        }}
        onClick={() => setSearchOpen(!searchOpen)}
      >
        <img width={25} height={25} src={searchIcon} alt="Search Icon" />
      </IconButton>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={3}
        px={1}
      >
        <Stack direction={'row'} spacing={2}>
          <img src={logoSrc} width={30} height={30} alt="Logo" />
          <Typography
            color={'black'}
            variant="h6"
            noWrap
            component="div"
            sx={{
              color: '#fff',
              fontSize: { xs: '1.5rem', md: '1.25rem' },
            }}
          >
            Lets Notify
          </Typography>
        </Stack>
      </Box>

      {searchOpen && (
        <Paper
          component="form"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '85%',
            backgroundColor: '#f0f2f5',
            marginX: 'auto',
            marginY: '10px',
            borderRadius: 1,
          }}
        >
          <InputBase
            sx={{ marginLeft: 2, flex: 1, color: '#100C08' }}
            placeholder="Search..."
            inputProps={{ 'aria-label': 'search' }}
            autoFocus
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <IconButton
            type="button"
            sx={{ padding: '10px', color: '#100C08' }}
            aria-label="clear"
            onClick={clearSearch}
          >
            <Close />
          </IconButton>
        </Paper>
      )}

      <List>
        {!searchQuery ? (
          SideMenuItems?.map((eachItem, index) => {
            return (
              <React.Fragment key={index}>
                <RenderListItem
                  key={index}
                  eachItem={eachItem}
                  index={index}
                  openIndex={openIndex}
                  handleClick={handleClick}
                  isActive={isActive}
                  navigate={navigate}
                />
              </React.Fragment>
            );
          })
        ) : (
          <>
            {filteredItems.map((eachItem, index) => {
              const IconComponent =
                isActive(eachItem.link) ||
                eachItem.child?.some((child) => isActive(child.link))
                  ? eachItem.icon.active
                  : eachItem.icon.inactive;
              return (
                <React.Fragment key={index}>
                  <ListItem disablePadding>
                    <SidebarListItem
                      selected={isActive(eachItem.link)}
                      sx={{
                        minHeight: 30,
                        marginTop: 0.5,
                        borderRadius: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#B2D8D2',
                        fontSize: '1.1rem', // Increased font size
                      }}
                      onClick={() => handleNavigate(eachItem.link)}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <IconComponent />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          eachItem.parentName
                            ? `${eachItem.name} (${eachItem.parentName})`
                            : eachItem.name
                        }
                        primaryTypographyProps={{
                          color: isActive(eachItem.link) ? '#fff' : '#B2D8D2',
                          fontSize: {
                            xs: '1.1rem', // Increased font size
                            sm: '1.2rem', // Increased font size
                            md: '1.1rem' // Increased font size
                          },
                        }}
                        sx={{ opacity: 1, marginX: 1 }}
                      />
                    </SidebarListItem>
                  </ListItem>
                </React.Fragment>
              );
            })}
          </>
        )}
      </List>

      <Box
        sx={{
          position: 'absolute',
          bottom: 10,
          left: 0,
          right: 0,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: { xs: 'none', sm: 'none', md: 'none', lg: 'start' },
          alignItems: { xs: 'none', sm: 'none', md: 'none', lg: 'center' },
          gap: 1,
          paddingX: 1,
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'white',
            color: '#033a32',
            borderColor: '#033a32',
            fontWeight: 500,
            fontSize: { xs: '0.875rem', sm: '1rem', md: '0.95rem' },
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#f0f0f0',
            },
            display: 'flex',
            alignItems: 'center',
            marginBottom: 1.5,
          }}
          startIcon={<TextsmsOutlinedIcon sx={{ color: '#033a32' }} />}
          onClick={handleFeatureRequest}
        >
          Feature Request
        </Button>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: { xs: '0.5rem', sm: '1rem' },
            width: '100%',
            position: 'relative', // Add positioning context
          }}
        >
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center',
              maxWidth: { xs: '80%', sm: '85%' },
              overflow: 'hidden',
            }}
          >
            <Avatar 
              src={profileImageUrl} 
              alt={name} 
              sx={{ 
                width: { xs: 40, sm: 48 }, 
                height: { xs: 40, sm: 48 }, 
                marginRight: { xs: 1, sm: 2 },
                flexShrink: 0, // Prevent avatar from shrinking
              }} 
            />
            <Box 
              sx={{ 
                overflow: 'hidden', 
                flexGrow: 1,
                minWidth: 0, // Ensure text truncation works
              }}
            >
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  fontWeight: 'bold', 
                  fontSize: { xs: '0.9rem', sm: '1rem' },
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '100%',
                  color: 'white', // Set name color to white
                  textShadow: '0 1px 2px rgba(0,0,0,0.3)', // Add subtle text shadow for better readability
                }}
              >
                {name}
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  fontSize: { xs: '0.8rem', sm: '0.9rem' },
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '100%',
                  color: 'rgba(255,255,255,0.8)', // Slightly translucent white for email
                  textShadow: '0 1px 2px rgba(0,0,0,0.2)', // Subtle text shadow
                }}
              >
                {email}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: { xs: '0.5rem', sm: '1rem' }, // Move three-dot menu slightly right
            }}
          >
            <MoreVertIcon
              style={{ 
                cursor: 'pointer', 
                fontSize: { xs: '1.2rem', sm: '1.5rem' }
              }}
              onClick={handlePopover}
              sx={{ 
                color: 'white', // Change color to white
                opacity: 0.8, // Slightly translucent for softer look
                transition: 'opacity 0.2s ease', // Add hover effect
                '&:hover': {
                  opacity: 1, // Full opacity on hover
                },
              }}
            />
          </Box>
        </Box>
      </Box>
      <Popover
        id="simple-popover"
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            width: { 
              lg: '90%', 
              md: '90%', 
              sm: '90%', 
              xs: '90%' 
            },
            maxWidth: '300px',
            border: `1px solid ${theme.palette.primary.grey}`,
            backgroundColor: theme.palette.background.paper,
            marginLeft: { lg: '2%', md: '2%', sm: '2%', xs: '2%' },
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
          },
        }}
        onClick={handleClose}
      >
        <Box sx={{ padding: 0, margin: 0 }}>
          <List
            sx={{
              borderRadius: 1,
              padding: 0,
              margin: 0,
              border: '1px solid #e0e0e0',
            }}
          >
            {ProfileMenuItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem sx={{ padding: 0, margin: 0, color: 'black' }}>
                  <ListItemButton
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent event from bubbling
                      if (item.link === protectedRoutes.logout) {
                        handleLogout();
                        handleClose();
                      } else {
                        if (item.link.startsWith('mailto:')) {
                          window.location.href = item.link;
                        } else if (item.name.startsWith('Terms')) {
                          window.open(item.link, '_blank');
                        } else if (
                          item.link.startsWith('http://') ||
                          item.link.startsWith('https://')
                        ) {
                          window.open(item.link, '_blank');
                        } else {
                          navigate(item.link);
                        }
                        handleClose();
                      }
                    }}
                    sx={{
                      ...theme.listItems?.buttonStyle,
                      borderRadius: 0,
                      padding: 1,
                      '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                      },
                    }}
                    selected={isActive(item.link)}
                  >
                    <ListItemIcon sx={{ minWidth: '40px', color: 'inherit' }}>
                      <item.icon />
                    </ListItemIcon>
                    <ListItemText
                      primary={item.name}
                      primaryTypographyProps={{ 
                        style: { 
                          color: '#000000',
                          fontSize: { xs: '0.9rem', sm: '1rem' } 
                        } 
                      }}
                    />
                  </ListItemButton>
                </ListItem>
                {index !== ProfileMenuItems.length - 1 && (
                  <Divider sx={{ margin: 0 }} />
                )}
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Popover>
    </Drawer>
  );
};
