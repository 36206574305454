import { Box, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState, useEffect } from 'react';
import logoSrc from '../../../assets/Images/Common/logo.png';
import loginImage from '../../../assets/Images/UnProtected/Login-page.png';
import signupImage from '../../../assets/Images/UnProtected/Signup-page.png';
import { SignUpContainer } from './SignUpContainer';
import { LoginContainer } from './LoginContainer';
import { ForgotPwdModal } from 'components/Modals';
import ConfirmPasswordContainer from './ChangePwdContainer';

export const Login = () => {
  const [isLogin, setLogin] = useState('login');
  const [openForgetPass, setOpenForgetPass] = useState(false);
  const [animationKey, setAnimationKey] = useState(0);
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));

  const loginFeatures = [
    'Real-time Push Notifications',
    'Targeted Customer Engagement',
    'Multi-channel Messaging',
    'Analytics-driven Communication'
  ];

  const signupFeatures = [
    'Easy Onboarding',
    'Customizable Notification Flows',
    'Secure User Management',
    'Instant Integration'
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setAnimationKey(prev => prev + 1);
    }, 3000);
    return () => clearInterval(timer);
  }, []);

  const showSignUp = () => {
    setLogin('signup');
  };

  const showLogin = () => {
    setLogin('login');
  };

  const showForgetPass = () => {
    setOpenForgetPass(true);
  };

  const sendToConfirmPass = () => {
    setOpenForgetPass(false);
    setLogin('confirm-pass');
  };

  const renderFeatures = (features) => (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        gap: 2, 
        mt: 3 
      }}
    >
      {features.map((feature, index) => (
        <Box 
          key={feature}
          sx={{
            display: 'flex', 
            alignItems: 'center', 
            opacity: 0,
            animation: `fadeIn 0.7s ease-in-out ${index * 0.2}s forwards`,
            '@keyframes fadeIn': {
              '0%': { opacity: 0, transform: 'translateX(-20px)' },
              '100%': { opacity: 1, transform: 'translateX(0)' }
            }
          }}
        >
          <Box 
            sx={{ 
              width: 10, 
              height: 10, 
              borderRadius: '50%', 
              bgcolor: '#08FFD9', 
              mr: 2 
            }} 
          />
          <Typography 
            color="#FFFFFF" 
            fontSize="1rem"
          >
            {feature}
          </Typography>
        </Box>
      ))}
    </Box>
  );

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        backgroundColor: '#044E43',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <Grid container sx={{ flex: 1, height: '100%', overflow: 'hidden' }}>
        {isMediumScreen && (
          <Grid
            item
            md={7.5}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              padding: 3,
              boxSizing: 'border-box',
              background: 'linear-gradient(135deg, #044E43 0%, #02322A 100%)',
            }}
          >
            <Box display="flex" alignItems="center" mb={2}>
              <IconButton disableRipple>
                <img width={50} src={logoSrc} alt="Logo" />
              </IconButton>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '2rem',
                  color: '#FFFFFF',
                }}
              >
                Lets Notify
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Box width="70%" pl={4} pt={2}>
                <Typography 
                  color="#FFFFFF" 
                  fontSize="1.8rem" 
                  key={`text-${animationKey}`}
                  sx={{
                    animation: `fadeIn 0.7s ease-in-out`,
                    '@keyframes fadeIn': {
                      '0%': { opacity: 0, transform: 'translateY(20px)' },
                      '100%': { opacity: 1, transform: 'translateY(0)' }
                    }
                  }}
                >
                  {isLogin === 'login'
                    ? 'Make every message count with precise push notifications using  '
                    : 'Never miss a beat—engage your customers with timely push alerts by using  '}
                  <span
                    style={{
                      fontSize: '1.8rem',
                      fontWeight: 'bold',
                      color: '#08FFD9',
                    }}
                  >
                    Lets Notify
                  </span>
                </Typography>

                {renderFeatures(isLogin === 'login' ? loginFeatures : signupFeatures)}
              </Box>
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'start',
                backgroundColor: 'transparent',
              }}
            >
              <img
                src={isLogin === 'login' ? loginImage : signupImage}
                style={{ 
                  maxWidth: '60%', 
                  animation: `float 3s ease-in-out infinite`,
                  '@keyframes float': {
                    '0%': { transform: 'translateY(0px)' },
                    '50%': { transform: 'translateY(-15px)' },
                    '100%': { transform: 'translateY(0px)' }
                  }
                }}
                alt="Form Filling"
                key={`image-${animationKey}`}
              />
            </Box>
          </Grid>
        )}
        <ForgotPwdModal
          open={openForgetPass}
          handleClose={() => setOpenForgetPass(false)}
          sendToConfirmPass={sendToConfirmPass}
        />
        <Grid item md={isMediumScreen ? 4.5 : 12} xs={12}>
          <Box
            height="100vh"
            width="100%"
            backgroundColor="#FFFFFF"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              padding: 3,
              boxSizing: 'border-box',
            }}
          >
            {isLogin === 'login' ? (
              <LoginContainer
                showSignUp={showSignUp}
                openForgetPass={showForgetPass}
              />
            ) : isLogin === 'signup' ? (
              <SignUpContainer showLogin={showLogin} />
            ) : (
              <ConfirmPasswordContainer navigateToLogin={showLogin} />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};