import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Trash2 } from 'lucide-react';
import { Button, TextField } from '@mui/material';
import EditableRadioGroup from './components/RadioComponent';
import EditableCheckboxGroup from './components/CheckboxComponent';
import EditableDropdown from './components/DropdownComponent';
import EditableTextInput from './components/InputComponent';

const FormElementComponent = ({ element, index, updateElement, moveElement, deleteElement }) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: 'form-element',
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            moveElement(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'form-element',
        item: () => {
            return { id: element.id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    const opacity = isDragging ? 0 : 1;

    const renderContent = () => {
        switch (element.type) {
            case 'TextInput':
                return <EditableTextInput label={element.label} />;
            case 'RadioGroup':
                return <EditableRadioGroup options={element.options || []} />;
            case 'CheckboxGroup':
                return <EditableCheckboxGroup options={element.options || []} />;
            case 'Dropdown':
                return <EditableDropdown options={element.options || []} />;
            default:
                return <div>Unknown element type</div>;
        }
    };

    return (
        <div
            ref={ref}
            style={{ opacity }}
            className="mb-4 p-4 bg-gray-50 rounded shadow"
        >
            <div className="flex justify-between items-center mb-2">
                <TextField
                    label="Label"
                    value={element.label}
                    onChange={(e) => updateElement(element.id, { label: e.target.value })}
                    fullWidth
                />
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => deleteElement(element.id)}
                >
                    <Trash2 />
                </Button>
            </div>
            <div className="mt-2">{renderContent()}</div>
        </div>
    );
};

export default FormElementComponent;
