import { api } from '../../../../Api/Interceptor';

// Base API URL - replace with your actual backend URL
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000/api';

/**
 * Generate a short, unique ID
 * @param {number} length - Length of the ID (default: 8)
 * @returns {string} - Short unique ID
 */
const generateShortId = (length = 8) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charsLength = chars.length;
    
    for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * charsLength));
    }
    
    return result;
};

/**
 * Create a new website with generated details
 * @param {Object} websiteData - Basic website information
 * @returns {Promise} - Promise resolving to the saved website data
 */
export const createWebsite = async (websiteData) => {
    try {
        // Generate short unique ID if not provided
        const websiteId = websiteData.id || generateShortId();

        // Prepare complete website object
        const completeWebsiteData = {
            id: websiteId,
            name: websiteData.name || 'Untitled Website',
            htmlCode: websiteData.htmlCode || '',
            cssCode: websiteData.cssCode || '',
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            // Optional: Add user ID if authentication is implemented
            userId: websiteData.userId || null
        };

        // Log the data being sent to backend
        console.group('Website Save Data');
        console.log('Website ID:', completeWebsiteData.id);
        console.log('Website Name:', completeWebsiteData.name);
        console.log('HTML Code Length:', completeWebsiteData.htmlCode.length);
        console.log('CSS Code Length:', completeWebsiteData.cssCode.length);
        console.log('Created At:', completeWebsiteData.createdAt);
        console.log('User ID:', completeWebsiteData.userId || 'Not Specified');
        console.log('Full Data:', completeWebsiteData);
        console.groupEnd();

        // Save website to backend
        const response = await api.post('websites', completeWebsiteData, {
            headers: { 
                'Content-Type': 'application/json' 
            }
        });

        // Return the saved website data
        return {
            ...completeWebsiteData,
            ...response.data
        };
    } catch (error) {
        console.error('Error creating website:', error);
        throw error;
    }
};

/**
 * Update an existing website
 * @param {string} websiteId - ID of the website to update
 * @param {Object} websiteData - Updated website information
 * @returns {Promise} - Promise resolving to the updated website data
 */
export const updateWebsite = async (websiteId, websiteData) => {
    try {
        // Prepare update object
        const updateData = {
            ...websiteData,
            updatedAt: new Date().toISOString()
        };

        // Log the data being sent to backend
        console.group('Website Update Data');
        console.log('Website ID:', websiteId);
        console.log('Updated Data:', updateData);
        console.groupEnd();

        // Update website in backend
        const response = await api.put(`websites/${websiteId}`, updateData, {
            headers: { 
                'Content-Type': 'application/json' 
            }
        });

        return response.data;
    } catch (error) {
        console.error('Error updating website:', error);
        throw error;
    }
};

/**
 * Save website data to the backend
 * @param {Object} websiteData - Comprehensive website details
 * @returns {Promise} - Promise resolving to the saved website data
 */
export const saveWebsite = async (websiteData) => {
    try {
        // Log the data being sent to backend
        console.group('Website Save Data');
        console.log('Full Data:', websiteData);
        console.groupEnd();

        const response = await api.post('websites', websiteData, {
            headers: { 
                'Content-Type': 'application/json' 
            }
        });
        return response;
    } catch (error) {
        console.error('Error saving website:', error);
        throw error;
    }
};

/**
 * Fetch website data by ID
 * @param {string} websiteId - ID of the website to fetch
 * @returns {Promise} - Promise resolving to the website data
 */
export const getWebsiteById = async (websiteId) => {
    try {
        const response = await api.get(`websites/${websiteId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching website:', error);
        throw error;
    }
};

/**
 * List all websites for a user
 * @param {string} userId - ID of the user
 * @returns {Promise} - Promise resolving to list of websites
 */
export const listUserWebsites = async (userId) => {
    try {
        const response = await api.get(`websites/user/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error listing websites:', error);
        throw error;
    }
};

/**
 * Delete a website by its ID
 * @param {string} websiteId - ID of the website to delete
 * @returns {Promise} - Promise resolving to deletion result
 */
export const deleteWebsite = async (websiteId) => {
    try {
        // Log the data being sent to backend
        console.group('Website Delete Data');
        console.log('Website ID:', websiteId);
        console.groupEnd();

        const response = await api.delete(`websites/${websiteId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting website:', error);
        throw error;
    }
};
