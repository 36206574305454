import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  List,
  Typography,
  TextField,
  Paper,
  ListItem,
  ListItemText,
  IconButton,
  InputAdornment,
  useMediaQuery,
  useTheme,
  Container,
  Grid,
  Tooltip,
} from '@mui/material';
import { GetAllKeys, TestNotification } from 'Api/Api';
import { useNavigate } from 'react-router-dom';
import { protectedRoutes } from 'constants/appRoutes';
import toast from 'react-hot-toast';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DownloadRounded, CodeRounded, WebRounded, CloudRounded, StorageRounded } from '@mui/icons-material';

// Import images
import WebIcon from '../../../assets/Images/Connection/HTML_CSS_Icon.jpg';
import WordpressIcon from '../../../assets/Images/Connection/Wordpress_Icon.png';
import ShopifyIcon from '../../../assets/Images/Connection/Shopify_Icon.png';
import JavaIcon from '../../../assets/Images/Connection/Java_Icon.png';

const CodeInjection = ({
  apiKey,
  secretKey,
  connectionId,
  setSecretKey,
  setApiKey,
}) => {
  const navigate = useNavigate();
  const textFieldRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const platformIcons = [
    { 
      icon: WebIcon, 
      name: 'Web', 
      description: 'Integrate push notifications into your web application',
      status: 'active' 
    },
    { 
      icon: WordpressIcon, 
      name: 'Wordpress', 
      description: 'Add push notifications to your Wordpress site',
      status: 'coming_soon' 
    },
    { 
      icon: ShopifyIcon, 
      name: 'Shopify', 
      description: 'Enable push notifications for your Shopify store',
      status: 'coming_soon' 
    },
    { 
      icon: JavaIcon, 
      name: 'Cloud', 
      description: 'Cloud-based push notification services',
      status: 'coming_soon' 
    }
  ];

  async function copyPublicKey() {
    const response = await GetAllKeys();
    if (response.status === 200) {
      navigator.clipboard.writeText(response.data.public_key);
      toast.success('Copied successfully');
    } else {
      toast.error(response.data.message);
    }
  }

  async function sendTestPushNotification() {
    try {
      const testPayload = {
        isTestNotification: true,
        subscriptionID: localStorage.getItem('letsNotifySubId'),
      };
      const response = await TestNotification(testPayload);
      if (response?.data?.status === true) {
        toast.success(response?.data.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  const handleCompleteSetup = () => {
    setApiKey('');
    setSecretKey('');
    navigate(protectedRoutes.connections);
  };

  const handleCopyClick = () => {
    if (textFieldRef.current) {
      const codeToCopy = textFieldRef.current.value;
      navigator.clipboard
        .writeText(codeToCopy)
        .then(() => {
          toast.success('Copied to clipboard!');
        })
        .catch((err) => {
          toast.error('Failed to copy: ', err);
          console.error('Failed to copy: ', err);
        });
    }
  };

  const downloadKeys = () => {
    const fileContent = `importScripts("https://cdn.letsnotify.co.in/sw")`;
    const blob = new Blob([fileContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'LetsNotifySW.js';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <Container 
      maxWidth="lg" 
      sx={{ 
        py: 2, 
        px: { xs: 1, sm: 2, md: 3 },
        width: '100%',
      }}
    >
      <Grid 
        container 
        spacing={2} 
        justifyContent="center" 
        alignItems="stretch"
        sx={{ mb: 3 }}
      >
        {platformIcons.map((platform, index) => (
          <Grid item xs={6} sm={3} key={index}>
            <Paper
              elevation={platform.status === 'active' ? 3 : 1}
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: 2,
                transition: 'all 0.3s ease',
                opacity: platform.status === 'active' ? 1 : 0.5,
                border: platform.status === 'active' 
                  ? `2px solid ${theme.palette.primary.main}` 
                  : '2px dashed gray',
                '&:hover': platform.status === 'active' ? {
                  transform: 'scale(1.05)',
                  boxShadow: theme.shadows[6],
                } : {},
              }}
            >
              <Tooltip 
                title={platform.description} 
                placement="top"
                arrow
              >
                <Box
                  sx={{
                    width: { xs: 60, sm: 80 },
                    height: { xs: 60, sm: 80 },
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img 
                    src={platform.icon} 
                    alt={platform.name} 
                    style={{ 
                      maxWidth: '100%', 
                      maxHeight: '100%', 
                      objectFit: 'contain' 
                    }} 
                  />
                </Box>
              </Tooltip>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  textAlign: 'center',
                  color: platform.status === 'active' 
                    ? theme.palette.text.primary 
                    : theme.palette.text.secondary,
                  fontWeight: platform.status === 'active' ? 600 : 400,
                }}
              >
                {platform.name}
              </Typography>
              {platform.status === 'coming_soon' && (
                <Typography 
                  variant="caption" 
                  color="secondary" 
                  sx={{ 
                    mt: 0.5, 
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                  }}
                >
                  Coming Soon
                </Typography>
              )}
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Paper 
        elevation={0} 
        sx={{ 
          p: { xs: 2, sm: 3, md: 4 }, 
          width: '100%',
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
          <Grid item xs={12} sm={8}>
            <Typography 
              variant="h5" 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                fontWeight: 600,
                color: theme.palette.primary.main,
              }}
            >
              <CodeRounded sx={{ mr: 1 }} />
              Web Integration Guide
            </Typography>
          </Grid>
          <Grid 
            item 
            xs={12} 
            sm={4} 
            sx={{ 
              display: 'flex', 
              justifyContent: { xs: 'flex-start', sm: 'flex-end' } 
            }}
          >
            <Button 
              variant="outlined" 
              startIcon={<DownloadRounded />} 
              onClick={downloadKeys}
              size={isMobile ? 'small' : 'medium'}
              sx={{ 
                borderColor: theme.palette.primary.light,
                color: theme.palette.primary.main,
              }}
            >
              Download SW
            </Button>
          </Grid>
        </Grid>

        <List sx={{ 
          listStyleType: 'disc', 
          pl: 2, 
          mb: 2,
          '& .MuiListItem-root': { 
            display: 'list-item', 
            padding: 0,
            mb: 1,
          }
        }}>
          <ListItem>
            <ListItemText 
              primary="Download LetsNotifySW.js in your project's root directory" 
              primaryTypographyProps={{ 
                variant: 'body2',
                color: 'text.secondary',
              }}
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Copy the code below and paste it into your index file" 
              primaryTypographyProps={{ 
                variant: 'body2',
                color: 'text.secondary',
              }}
            />
          </ListItem>
        </List>

        <Box sx={{ position: 'relative', mb: 2 }}>
          <TextField
            multiline
            rows={isMobile ? 10 : 14}
            fullWidth
            variant="outlined"
            defaultValue={`<script src="https://cdn.letsnotify.co.in/web/push/v1"></script>
<script>
  const sdk = new LetsNotifySDK();
  const pushSDK = sdk.pushSdk();
  pushSDK.init({
    connectionId: "${connectionId || 'your-connection-id'}",
    serviceWorkerPath: "LetsNotifySW.js",
    apiKey: "${apiKey || 'your-api-key'}",
    secretKey: "${secretKey || 'your-secret-key'}"
  });
</script>`}
            InputProps={{
              readOnly: true,
              style: {
                fontFamily: 'monospace',
                fontSize: isMobile ? '0.75rem' : '0.875rem',
                backgroundColor: theme.palette.background.paper,
              },
              endAdornment: (
                <InputAdornment 
                  position="end" 
                  sx={{ 
                    position: 'absolute', 
                    top: '20px', 
                    right: '10px' 
                  }}
                >
                  <IconButton 
                    size="small" 
                    onClick={handleCopyClick}
                    sx={{ 
                      color: theme.palette.primary.main,
                      '&:hover': { 
                        backgroundColor: theme.palette.action.hover 
                      }
                    }}
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 2,
              },
            }}
            inputRef={textFieldRef}
          />
        </Box>

        <Typography 
          variant="body2" 
          sx={{ 
            mb: 2, 
            p: 1.5, 
            backgroundColor: theme.palette.action.hover,
            borderRadius: 1,
            color: theme.palette.text.secondary,
          }}
        >
          After integration, visit your website to see the browser permission popup. 
          Allow notifications to receive test push notifications.
        </Typography>

        <Grid 
          container 
          spacing={2} 
          justifyContent="center" 
          sx={{ mt: 2 }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Button 
              fullWidth
              variant="contained" 
              onClick={handleCompleteSetup}
              size={isMobile ? 'small' : 'medium'}
              sx={{ 
                backgroundColor: theme.palette.primary.main,
                '&:hover': { 
                  backgroundColor: theme.palette.primary.dark 
                }
              }}
            >
              Complete Setup
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button 
              fullWidth
              variant="outlined" 
              onClick={sendTestPushNotification}
              size={isMobile ? 'small' : 'medium'}
              sx={{ 
                borderColor: theme.palette.primary.light,
                color: theme.palette.primary.main,
              }}
            >
              Test Notification
            </Button>
          </Grid>
        </Grid>

        <Typography 
          variant="body2" 
          align="center"
          sx={{ 
            mt: 3, 
            p: 2, 
            backgroundColor: theme.palette.action.hover,
            borderRadius: 1,
            color: theme.palette.text.secondary,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
          }}
        >
          To connect with specific providers, 
          <Typography 
            component="span" 
            sx={{ 
              fontWeight: 'bold', 
              color: theme.palette.primary.main,
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
            onClick={() => window.location.href = 'mailto:support@letsnotify.co.in'}
          >
            email us
          </Typography>
        </Typography>
      </Paper>
    </Container>
  );
};

export default CodeInjection;
