import { Box, Checkbox, Divider, FormControlLabel, IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetUserDetails, LoginUser } from 'Api/Api';
import { CustomFormInput } from 'components/Style';
import { RoundedButton } from 'components/Buttons';
import { setAuthDetails } from '../../../redux/reducers/authReducer';
import { isEmailValid } from 'utils/commonFunctions';
import { useForm } from 'react-hook-form';
import { protectedRoutes } from 'constants/appRoutes';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export const LoginContainer = ({ showSignUp, openForgetPass }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({ 
    mode: 'onChange',
    defaultValues: {
      rememberMe: false
    }
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginDetails, setLoginDetails] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  let flag = false;

  function onChangeReCaptcha(e) {
    setLoginDetails((x) => ({ ...x, ['reCaptcha']: e }));
  }

  const validatePassword = (value) => {
    if (!value) return 'Password is required';
    if (value.length < 8) return 'Password must be at least 8 characters';
    if (!/[A-Z]/.test(value)) return 'Password must contain an uppercase letter';
    if (!/[a-z]/.test(value)) return 'Password must contain a lowercase letter';
    if (!/[0-9]/.test(value)) return 'Password must contain a number';
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) return 'Password must contain a special character';
    return true;
  };

  const handleLogin = async (data) => {
    if (!loginDetails.reCaptcha) {
      toast.error("Please verify that you're not a robot");
      return;
    }
    
    setLoading(true);
    try {
      const response = await toast.promise(LoginUser(data), {
        loading: 'Authenticating...',
        success: (response) => response?.data?.message,
        error: (error) => error?.response?.data?.message,
      });
      
      if (response?.data?.status === true) {
        var token = response?.data?.accessToken;
        var userDetails = response?.data?.user;
        
        // Handle remember me functionality
        if (data.rememberMe) {
          localStorage.setItem('rememberedEmail', data.email);
        } else {
          localStorage.removeItem('rememberedEmail');
        }
        
        localStorage.clear();
        localStorage.setItem('token', token);
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
        
        const payload = {
          token: token,
          authUser: true,
        };
        dispatch(setAuthDetails(payload));
        flag = true;
      }
    } catch (error) {
      setLoading(false);
      return;
    }
    
    try {
      if (flag) {
        const response = await GetUserDetails();
        if (
          response?.data?.status === true &&
          response?.data?.data?.customerData !== null
        ) {
          navigate(protectedRoutes.connections);
        } else {
          navigate(protectedRoutes.questions);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        padding: 4,
        boxShadow: 6,
        borderRadius: 3,
        width: '100%',
        maxWidth: 450,
        boxSizing: 'border-box',
        background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
      }}
    >
      <Typography variant="h4" textAlign="center" sx={{ fontWeight: 'bold', mb: 2 }}>
        Welcome Back
      </Typography>
      <Typography variant="subtitle1" textAlign="center" color="text.secondary" sx={{ mb: 3 }}>
        Sign in to continue to Lets Notify
      </Typography>
      
      <form onSubmit={handleSubmit(handleLogin)}>
        <Stack direction={'column'} spacing={3}>
          <Box>
            <Typography mb={1} variant="subtitle2">
              Email Address
            </Typography>
            <CustomFormInput
              placeholder="Enter your email"
              {...register('email', {
                required: 'Email is required',
                validate: {
                  validEmail: (value) =>
                    isEmailValid(value) || 'Please enter a valid email address',
                },
              })}
              defaultValue={localStorage.getItem('rememberedEmail') || ''}
            />
            {errors.email && (
              <Typography sx={{ color: 'error.main', mt: 1, fontSize: '0.75rem' }}>
                {errors?.email?.message}
              </Typography>
            )}
          </Box>
          
          <Box>
            <Typography mb={1} variant="subtitle2">
              Password
            </Typography>
            <Box sx={{ position: 'relative' }}>
              <CustomFormInput
                placeholder="Enter your password"
                type={showPassword ? 'text' : 'password'}
                {...register('password', {
                  validate: validatePassword,
                })}
              />
              <IconButton
                disableRipple
                onClick={() => setShowPassword((prev) => !prev)}
                sx={{
                  position: 'absolute',
                  right: 10,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: 'text.secondary',
                }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </Box>
            {errors.password && (
              <Typography sx={{ color: 'error.main', mt: 1, fontSize: '0.75rem' }}>
                {errors?.password?.message}
              </Typography>
            )}
          </Box>
          
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center' 
            }}
          >
            <FormControlLabel
              control={
                <Checkbox 
                  {...register('rememberMe')} 
                  color="primary" 
                />
              }
              label="Remember me"
            />
            <Typography
              variant="subtitle2"
              sx={{
                textDecoration: 'underline',
                cursor: 'pointer',
                color: 'primary.main',
              }}
              onClick={openForgetPass}
            >
              Forgot Password?
            </Typography>
          </Box>
          
          <Box my={2} px={3}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
              onChange={onChangeReCaptcha}
            />
          </Box>
          
          <RoundedButton 
            title="Login" 
            type="submit" 
            disabled={loading}
            sx={{ py: 1.5 }}
          />
        </Stack>
      </form>
      
      <Typography variant="subtitle2" textAlign={'center'} mt={3}>
        Don't have an account?{' '}
        <span
          style={{ 
            textDecoration: 'underline', 
            cursor: 'pointer',
            color: 'primary.main'
          }}
          onClick={showSignUp}
        >
          Sign up
        </span>
      </Typography>
    </Box>
  );
};
