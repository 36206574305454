import React, { useState } from 'react';
import { Box, Switch, Typography, FormControl, Button } from '@mui/material';
import { DeleteCampaign } from 'Api/Api';
import DateTimepicker from 'components/DateTimePicker';
import toast from 'react-hot-toast';
import { formatDateTime } from 'utils/commonFunctions';
import { InnerTextField } from 'components/InputFields';
import { CustomSelect } from 'components/CustomSelect';
import { CampaignScheuduler } from 'constants/appConstant';
import EditCampaignModal from '../EditCampaignModal';
import { editIcon, trashIcon } from 'constants/appImages';
import { DeleteModal } from 'components/Modals';
import { Loading } from 'components/Loading';

const Details = ({
  open,
  onClose,
  itemId,
  refresh,
  templates = [],
  segments = [],
  fetchSegment,
  fetchTemplate,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloseDelete = () => {
    onClose();
    setDeleteModalOpen(false);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    onClose();
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const response = await DeleteCampaign(itemId.id);
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        refresh();
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading state={loading} />;
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontSize={'18px'} fontWeight={600} color={'black'}>
          Campaign Details
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            onClick={handleOpen}
            sx={{ minWidth: 'auto', p: 0.5 }}
            disableRipple
          >
            <img src={editIcon} alt="editIcon" width={23} height={23} />
          </Button>
          <Button
            onClick={() => setDeleteModalOpen(true)}
            sx={{ minWidth: 'auto', p: 0.5 }}
            disableRipple
          >
            <img src={trashIcon} alt="deleteIcon" width={23} height={23} />
          </Button>
        </Box>
      </Box>

      <EditCampaignModal
        open={openModal}
        onClose={handleClose} 
        itemId={itemId}
        refresh={refresh}
        templates={templates}
        segments={segments}
        FetchTemplate={fetchTemplate}
        FetchSegment={fetchSegment}
      />

      <DeleteModal
        open={deleteModalOpen}
        close={handleCloseDelete}
        placeholder="Campaign"
        deleteFunction={() => handleDelete()}
      />

      <Box>
        <InnerTextField
          label="Campaign Name"
          value={itemId?.campaignName || ''}
          readOnly
          fullWidth
        />
      </Box>

      <Box>
        <InnerTextField
          label="Template"
          value={itemId?.template?.templateName || ''}
          readOnly
          fullWidth
        />
      </Box>

      <Box>
        <InnerTextField
          label="Segment"
          value={itemId?.segment?.segmentName || ''}
          readOnly
          fullWidth
        />
      </Box>

      <Box>
        <Typography variant="subtitle2">Status</Typography>
        <Switch
          name="status"
          size="large"
          checked={itemId?.status === 'Active'}
          readOnly={true}
        />
      </Box>

      <Box display="flex" alignItems="center" gap={2}>
        <FormControl sx={{ width: '44%' }}>
          <CustomSelect
            label="Schedule"
            options={CampaignScheuduler}
            value={itemId?.frequency || ''}
            readOnly={true}
            // onChange={handleFrequencyChange}
          />
        </FormControl>

        {itemId?.frequency === 'on_specific_date' && (
          <DateTimepicker
            label="Date and time"
            // onChangeval={(e) =>
            //   setCampaignDetails((prev) => ({ ...prev, frequencyDateTime: e }))
            // }
          />
        )}
      </Box>

      <Box>
        <Typography variant="subtitle2" my={1}>
          Created time: {formatDateTime(itemId?.createdTime)}
        </Typography>
        <Typography variant="subtitle2" my={1}>
          Modified time: {formatDateTime(itemId?.modifiedTime)}
        </Typography>
      </Box>
    </Box>
  );
};

export default Details;
