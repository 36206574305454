import React from "react";
import { 
  Box, 
  Button, 
  Typography, 
  Grid, 
  useTheme, 
  useMediaQuery, 
  Tooltip,
  Modal,
  IconButton
} from "@mui/material";
import { InnerTextField } from "components/InputFields";
import { useEffect, useState } from "react";
import { formatDateTime } from "utils/commonFunctions";
import EditCustomPromptModal from "../customPrompt/EditCustomPromptModal";
import { editIcon, trashIcon } from "constants/appImages";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CloseIcon from '@mui/icons-material/Close';
import { DeleteModal } from "components/Modals";
import { DeleteCustomPrompt } from 'Api/Api';
import toast from 'react-hot-toast';
import NoRecord from 'components/NoRecord';
import CreateCustompromptPage from '../customPrompt/CreateCustomPrompt';
import { Loading } from 'components/Loading';
import { Time } from '../customPrompt/constant';

export const CustomPrompt = ({ item, initialLoading, customPrompt, refreshCustom, refresh, setCustomPrompts }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const [openModal, setOpenModal] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [imagePreview, setImagePreview] = useState(false);
    const [flag, setFlag] = useState(customPrompt !== null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setFlag(customPrompt !== null)
    }, [customPrompt]);

    const handleOpen = () => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleImagePreview = () => {
        setImagePreview(!imagePreview);
    };

    const handleDelete = async () => {
        try {
            setLoading(true);
            let response = await DeleteCustomPrompt(customPrompt.id);
            if (response?.status === 200) {
                toast.success(response?.data?.message);
                setCustomPrompts(null);
                refresh()
                refreshCustom()
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            toast.error(err?.response?.data?.message);
        }
    };

    if (loading) {
        return <Loading state={loading} />;
    }

    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <Box 
            sx={{ 
                width: '100%', 
                maxWidth: { xs: '100%', sm: '500px', md: '600px' },
                margin: 'auto',
                padding: theme.spacing(2)
            }}
        >
            {flag ? (
                <Box>
                    <Box 
                        display="flex" 
                        justifyContent="space-between" 
                        alignItems="center" 
                        marginBottom={2}
                    >
                        <Typography 
                            fontSize={'18px'} 
                            fontWeight={600} 
                            color={'black'}
                        >
                            Custom Prompt Details
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Tooltip title="Edit Custom Prompt">
                                <Button
                                    onClick={handleOpen}
                                    sx={{ 
                                        minWidth: 'auto', 
                                        p: 0.5,
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                            transform: 'scale(1.1)'
                                        }
                                    }}
                                    disableRipple
                                >
                                    <img src={editIcon} alt="editIcon" width={23} height={23} />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Delete Custom Prompt">
                                <Button
                                    onClick={() => setDeleteModalOpen(true)}
                                    sx={{ 
                                        minWidth: 'auto', 
                                        p: 0.5,
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                            transform: 'scale(1.1)'
                                        }
                                    }}
                                    disableRipple
                                >
                                    <img src={trashIcon} alt="deleteIcon" width={23} height={23} />
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>

                    <EditCustomPromptModal
                        open={openModal}
                        onClose={handleClose}
                        itemId={customPrompt}
                        refreshCustom={refreshCustom}
                        refresh={refresh}
                    />

                    <DeleteModal
                        open={deleteModalOpen}
                        close={() => setDeleteModalOpen(false)}
                        deleteFunction={handleDelete}
                        title="Delete Custom Prompt"
                        description="Are you sure you want to delete this custom prompt? This action cannot be undone and will remove the prompt permanently from your connections."
                        placeholder={{
                            description: customPrompt?.data?.description
                        }}
                    />

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <InnerTextField
                                required
                                fullWidth
                                name="title"
                                label="Title"
                                value={customPrompt?.data?.title || ''}
                                readOnly
                                sx={{
                                    '& .MuiInputBase-input': {
                                        WebkitUserSelect: 'text',
                                        userSelect: 'text',
                                        cursor: 'text',
                                    }
                                }}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <InnerTextField
                                required
                                fullWidth
                                name="description"
                                label="Description"
                                value={customPrompt?.data?.description || ''}
                                readOnly
                                sx={{
                                    '& .MuiInputBase-input': {
                                        WebkitUserSelect: 'text',
                                        userSelect: 'text',
                                        cursor: 'text',
                                    }
                                }}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Box marginY={2}>
                        <Box 
                            display="flex" 
                            alignItems="center" 
                            justifyContent="space-between" 
                            marginBottom={1}
                        >
                            <Typography 
                                variant="subtitle1" 
                                fontWeight={500}
                                sx={{ 
                                    fontSize: '16px', 
                                    color: 'black' 
                                }}
                            >
                                Notification Icon
                            </Typography>
                        </Box>
                        <Box 
                            sx={{
                                position: 'relative',
                                width: '100%',
                                maxWidth: { xs: '250px', sm: '300px', md: '400px' },
                                aspectRatio: '16/9',
                                borderRadius: 2,
                                overflow: 'hidden',
                                boxShadow: 2,
                                transition: 'transform 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.02)',
                                }
                            }}
                        >
                            {customPrompt?.iconImageURL ? (
                                <>
                                    <img
                                        src={customPrompt?.iconImageURL}
                                        alt="Notification Icon"
                                        style={{
                                            width: '100%', 
                                            height: '100%', 
                                            objectFit: 'cover'
                                        }}
                                    />
                                    <Tooltip title="View Full Image">
                                        <Button
                                            onClick={handleImagePreview}
                                            sx={{
                                                position: 'absolute',
                                                top: 8,
                                                right: 8,
                                                backgroundColor: 'rgba(255,255,255,0.7)',
                                                minWidth: 'auto',
                                                p: 1,
                                                borderRadius: '50%',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255,255,255,0.9)',
                                                }
                                            }}
                                        >
                                            <ZoomInIcon fontSize="small" />
                                        </Button>
                                    </Tooltip>
                                </>
                            ) : (
                                <Typography 
                                    variant="body2" 
                                    color="text.secondary"
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                    }}
                                >
                                    No image uploaded
                                </Typography>
                            )}
                        </Box>
                    </Box>

                    <Box my={1}>
                        <Typography sx={{ fontSize: '18px' }} variant="black_p">
                            Button
                        </Typography>
                    </Box>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <InnerTextField
                                required
                                name="allowButtonText"
                                label="Allow Button Label"
                                value={customPrompt?.data?.styles?.allowButtonText || ''}
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <InnerTextField
                                required
                                name="allowButtonBackgroundColor"
                                label="Background Color"
                                colorSelection
                                value={customPrompt?.data?.styles?.allowButtonBackgroundColor || ''}
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <InnerTextField
                                required
                                name="allowButtonTextColor"
                                label="Text Color"
                                colorSelection
                                value={customPrompt?.data?.styles?.allowButtonTextColor || ''}
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <InnerTextField
                                required
                                name="laterButtonText"
                                label="Later Button Label"
                                value={customPrompt?.data?.styles?.laterButtonText || ''}
                                readOnly
                            />
                        </Grid>
                    </Grid>

                    <Box my={1}>
                        <Typography
                            sx={{ fontSize: '18px' }}
                            variant="subtitle3"
                        >
                            Opt-in Timings
                        </Typography>
                    </Box>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <InnerTextField
                                label="Mobile (In Sec)"
                                name="mobileTiming"
                                fullWidth
                                value={customPrompt?.data?.styles?.mobileTiming || ''}
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InnerTextField
                                label="Desktop (In Sec)"
                                name="desktopTiming"
                                fullWidth
                                options={Time}
                                value={customPrompt?.data?.styles?.desktopTiming || ''}
                                readOnly
                            />
                        </Grid>
                    </Grid>

                    <Box my={1}>
                        <Typography
                            sx={{ fontSize: '18px' }}
                            variant="subtitle3"
                        >
                            Prompt Frequency
                        </Typography>
                    </Box>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <InnerTextField
                                required
                                name="hidePromptFrequency"
                                label="Hide Prompt Frequency"
                                value={customPrompt?.data?.styles?.hidePromptFrequency || ''}
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InnerTextField
                                required
                                name="showPromptFrequency"
                                label="Show Prompt Frequency"
                                value={customPrompt?.data?.styles?.showPromptFrequency || ''}
                                readOnly
                            />
                        </Grid>
                    </Grid>

                    <Box my={1}>
                        <Typography
                            sx={{ fontSize: '18px' }}
                            variant="subtitle3"
                        >
                            Position
                        </Typography>
                    </Box>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <InnerTextField
                                label="Desktop"
                                name="desktopPosition"
                                fullWidth
                                value={customPrompt?.data?.styles?.desktopPosition || ''}
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InnerTextField
                                label="Mobile"
                                name="mobilePosition"
                                fullWidth
                                value={customPrompt?.data?.styles?.mobilePosition || ''}
                                readOnly
                            />
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <Box>
                    <NoRecord
                        type="callback"
                        moduleName="Custom Prompt"
                        onAction={handleOpen}
                    />
                    <CreateCustompromptPage
                        onClose={handleClose}
                        open={openModal}
                        item={item}
                        refreshCustom={refreshCustom}
                        refresh={refresh}
                    />
                </Box>
            )}

            {/* Full Image Preview Modal */}
            {imagePreview && customPrompt?.iconImageURL && (
                <Modal
                    open={imagePreview}
                    onClose={handleImagePreview}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box 
                        sx={{
                            maxWidth: '90%',
                            maxHeight: '90%',
                            outline: 'none',
                            position: 'relative',
                        }}
                    >
                        <IconButton
                            onClick={handleImagePreview}
                            sx={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: 'rgba(0,0,0,0.7)',
                                }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <img 
                            src={customPrompt?.iconImageURL} 
                            alt="Full Image" 
                            style={{
                                width: '100%', 
                                height: '100%', 
                                objectFit: 'contain',
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }} 
                        />
                    </Box>
                </Modal>
            )}
        </Box>
    );
};

export default CustomPrompt;
