import React, { useState } from 'react';
import { Box, Icon, Tabs } from '@mui/material';
import EnlargeModal, {
  a11yProps,
  CustomTab,
  CustomTabPanel,
  SvgSeparator,
} from 'components/EnlargeModal';

import DetailIconInActive from '../../../assets/Icons/SideBar/details.png';
import DetailIconActive from '../../../assets/Icons/SideBar/details 1.png';
import ActivityIconInActive from '../../../assets/Icons/SideBar/activity-1.png';
import ActivityIconActive from '../../../assets/Icons/SideBar/activity.png';
import Details from './Enlarge/Details';
import Activity from './Enlarge/Activity';

const EnlargeCampaign = ({
  open,
  onClose,
  itemId,
  refresh,
  templates,
  segments,
  fetchSegment,
  fetchTemplate,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <EnlargeModal open={open} onCloseModal={onClose} title={'Campaign'}>
      <Box width={'100%'}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="campaign tabs"
            sx={{ minHeight: '40px' }}
          >
            <CustomTab
              label="Details"
              {...a11yProps(0)}
              icon={
                <Icon sx={{ marginTop: '5px', marginRight: '0px !important' }}>
                  <img
                    src={value === 4 ? DetailIconActive : DetailIconInActive}
                    alt="Subscribers Icon"
                    style={{ width: '20px', height: '20px' }}
                  />
                </Icon>
              }
              iconPosition="start"
            />
            <SvgSeparator />
            <CustomTab
              label="Activity"
              {...a11yProps(2)}
              icon={
                <Icon sx={{ marginTop: '5px', marginRight: '0px !important' }}>
                  <img
                    src={
                      value === 6 ? ActivityIconActive : ActivityIconInActive
                    }
                    alt="Subscribers Icon"
                    style={{ width: '20px', height: '20px' }}
                  />
                </Icon>
              }
              iconPosition="start"
            />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <Details
            itemId={itemId}
            templates={templates}
            segments={segments}
            FetchTemplate={fetchTemplate}
            FetchSegment={fetchSegment}
            refresh={refresh}
          />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={2}>
          <Activity item={itemId} />
        </CustomTabPanel>
      </Box>
    </EnlargeModal>
  );
};

export default EnlargeCampaign;