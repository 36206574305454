import React, { useEffect, useRef, useState } from 'react';
import grapesjs from 'grapesjs';
import { 
    Button, 
    AppBar, 
    Box, 
    Toolbar, 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    TextField, 
    Tooltip,
    IconButton,
    DialogContentText
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import customCodePlugin from 'grapesjs-custom-code';
import grapesjsNavbar from 'grapesjs-navbar';
import CustomBlocks from '../builder/CustomBuilder';
import blockBasic from 'grapesjs-blocks-basic';
import FormPlugin from 'grapesjs-plugin-forms';
import 'grapesjs/dist/css/grapes.min.css';
import './editor.css';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SettingsIcon from '@mui/icons-material/Settings';
import { createWebsite } from '../services/websiteService';

const Editor = ({ onClose }) => {
    const editorRef = useRef(null);
    const [jsCodeDialogOpen, setJsCodeDialogOpen] = useState(false);
    const [jsCode, setJsCode] = useState('');
    const [editorSettings, setEditorSettings] = useState({
        showBorders: true,
        gridSnap: true,
        responsiveView: true
    });
    const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [saveWarningOpen, setSaveWarningOpen] = useState(false);
    const [websiteNameDialogOpen, setWebsiteNameDialogOpen] = useState(false);
    const [websiteName, setWebsiteName] = useState('');
    const [websiteDetails, setWebsiteDetails] = useState({
        id: uuidv4(),
        name: '',
        html: '',
        css: '',
        js: ''
    });

    useEffect(() => {
        if (!editorRef.current) {
            const editorDarkThemeCSS = `
                .gjs-editor {
                    background-color: #1e1e1e !important;
                }
                .gjs-cv-canvas {
                    background-color: #2c2c2c !important;
                }
                .gjs-blocks-container {
                    background-color: #252525 !important;
                    color: #ffffff !important;
                }
                .gjs-block {
                    background-color: #333333 !important;
                    color: #ffffff !important;
                    border: 1px solid #444444 !important;
                }
                .gjs-sm-sector-title {
                    background-color: #333333 !important;
                    color: #ffffff !important;
                }
                .gjs-sm-property {
                    background-color: #2c2c2c !important;
                    color: #ffffff !important;
                }
            `;

            const styleElement = document.createElement('style');
            styleElement.textContent = editorDarkThemeCSS;
            document.head.appendChild(styleElement);

            const editor = grapesjs.init({
                container: '#gjs',
                height: '100vh',
                fromElement: true,
                storageManager: { type: 'none' },
                plugins: [
                    customCodePlugin, 
                    grapesjsNavbar, 
                    blockBasic, 
                    FormPlugin,
                    (editor) => {
                        // Custom commands
                        editor.Commands.add('open-js', {
                            run(editor) {
                                setJsCodeDialogOpen(true);
                            }
                        });

                        // Undo/Redo commands
                        editor.Commands.add('core:undo', {
                            run: (editor) => editor.UndoManager.undo()
                        });
                        editor.Commands.add('core:redo', {
                            run: (editor) => editor.UndoManager.redo()
                        });
                    }
                ],
                pluginsOpts: {
                    'grapesjs-custom-code': {
                        blockCustomCode: {
                            label: 'Custom Code',
                            category: 'Extra',
                        },
                    },
                },
                allowScripts: true,
                
                // Minimal Style Manager Configuration
                styleManager: {
                    sectors: [
                        {
                            name: 'General',
                            open: true,
                            properties: [
                                {
                                    type: 'color',
                                    name: 'Background Color',
                                    property: 'background-color',
                                    defaults: '#1e1e1e'
                                },
                                {
                                    type: 'color',
                                    name: 'Text Color',
                                    property: 'color',
                                    defaults: '#ffffff'
                                }
                            ]
                        },
                        {
                            name: 'Dimensions',
                            open: false,
                            properties: [
                                {
                                    type: 'text',
                                    name: 'Width',
                                    property: 'width',
                                    units: ['px', '%', 'em', 'rem']
                                },
                                {
                                    type: 'text',
                                    name: 'Height',
                                    property: 'height',
                                    units: ['px', '%', 'em', 'rem']
                                },
                                {
                                    type: 'text',
                                    name: 'Padding',
                                    property: 'padding',
                                    units: ['px', '%', 'em', 'rem']
                                },
                                {
                                    type: 'text',
                                    name: 'Margin',
                                    property: 'margin',
                                    units: ['px', '%', 'em', 'rem']
                                }
                            ]
                        },
                        {
                            name: 'Typography',
                            open: false,
                            properties: [
                                {
                                    type: 'select',
                                    name: 'Font Family',
                                    property: 'font-family',
                                    options: [
                                        { value: 'Arial, sans-serif', name: 'Arial' },
                                        { value: 'Helvetica, sans-serif', name: 'Helvetica' },
                                        { value: 'Times New Roman, serif', name: 'Times New Roman' },
                                        { value: 'Courier New, monospace', name: 'Courier New' },
                                        { value: 'Georgia, serif', name: 'Georgia' },
                                        { value: 'Verdana, sans-serif', name: 'Verdana' }
                                    ]
                                },
                                {
                                    type: 'number',
                                    name: 'Font Size',
                                    property: 'font-size',
                                    units: ['px', 'rem', 'em']
                                },
                                {
                                    type: 'select',
                                    name: 'Font Weight',
                                    property: 'font-weight',
                                    options: [
                                        { value: '300', name: 'Light' },
                                        { value: '400', name: 'Normal' },
                                        { value: '600', name: 'Semi-Bold' },
                                        { value: '700', name: 'Bold' }
                                    ]
                                },
                                {
                                    type: 'select',
                                    name: 'Text Align',
                                    property: 'text-align',
                                    options: [
                                        { value: 'left', name: 'Left' },
                                        { value: 'center', name: 'Center' },
                                        { value: 'right', name: 'Right' },
                                        { value: 'justify', name: 'Justify' }
                                    ]
                                }
                            ]
                        },
                        {
                            name: 'Decorations',
                            open: false,
                            properties: [
                                {
                                    type: 'select',
                                    name: 'Border Style',
                                    property: 'border-style',
                                    options: [
                                        { value: 'none', name: 'None' },
                                        { value: 'solid', name: 'Solid' },
                                        { value: 'dashed', name: 'Dashed' },
                                        { value: 'dotted', name: 'Dotted' },
                                        { value: 'double', name: 'Double' }
                                    ]
                                },
                                {
                                    type: 'text',
                                    name: 'Border Width',
                                    property: 'border-width',
                                    units: ['px']
                                },
                                {
                                    type: 'color',
                                    name: 'Border Color',
                                    property: 'border-color'
                                },
                                {
                                    type: 'text',
                                    name: 'Border Radius',
                                    property: 'border-radius',
                                    units: ['px', '%']
                                },
                                {
                                    type: 'select',
                                    name: 'Box Shadow',
                                    property: 'box-shadow',
                                    options: [
                                        { value: 'none', name: 'None' },
                                        { value: '0 2px 4px rgba(0,0,0,0.1)', name: 'Light Shadow' },
                                        { value: '0 4px 6px rgba(0,0,0,0.2)', name: 'Medium Shadow' },
                                        { value: '0 6px 8px rgba(0,0,0,0.3)', name: 'Dark Shadow' }
                                    ]
                                }
                            ]
                        },
                        {
                            name: 'Positioning',
                            open: false,
                            properties: [
                                {
                                    type: 'select',
                                    name: 'Position',
                                    property: 'position',
                                    options: [
                                        { value: 'static', name: 'Static' },
                                        { value: 'relative', name: 'Relative' },
                                        { value: 'absolute', name: 'Absolute' },
                                        { value: 'fixed', name: 'Fixed' }
                                    ]
                                },
                                {
                                    type: 'text',
                                    name: 'Top',
                                    property: 'top',
                                    units: ['px', '%']
                                },
                                {
                                    type: 'text',
                                    name: 'Left',
                                    property: 'left',
                                    units: ['px', '%']
                                },
                                {
                                    type: 'text',
                                    name: 'Right',
                                    property: 'right',
                                    units: ['px', '%']
                                },
                                {
                                    type: 'text',
                                    name: 'Bottom',
                                    property: 'bottom',
                                    units: ['px', '%']
                                }
                            ]
                        }
                    ]
                },
                
                // Basic Device Manager
                deviceManager: {
                    devices: [
                        { name: 'Desktop', width: '100%' },
                        { name: 'Tablet', width: '768px' },
                        { name: 'Mobile', width: '320px' }
                    ]
                }
            });
            
            editorRef.current = editor;
            CustomBlocks(editor);

            // Track changes
            editor.on('component:add', updateWebsiteContent);
            editor.on('component:remove', updateWebsiteContent);
            editor.on('component:update', updateWebsiteContent);
            editor.on('style:update', updateWebsiteContent);
        }
    }, []);

    const updateWebsiteContent = () => {
        if (editorRef.current) {
            const html = editorRef.current.getHtml();
            const css = editorRef.current.getCss();
            
            setWebsiteDetails(prev => ({
                ...prev,
                html,
                css,
                js: jsCode || ''
            }));
            setHasUnsavedChanges(true);
        }
    };

    const handleSave = () => {
        // Open website name dialog if no name is provided
        if (!websiteDetails.name) {
            setWebsiteNameDialogOpen(true);
        } else {
            performSave();
        }
    };

    const performSave = async () => {
        try {
            const editor = editorRef.current;
            if (!editor) return;

            // Prepare complete website details
            const completeWebsiteDetails = {
                ...websiteDetails,
                name: websiteName || 'Untitled Website',
                htmlCode: editor.getHtml(),
                cssCode: editor.getCss()
            };

            // Call backend service to save website
            const savedWebsite = await createWebsite(completeWebsiteDetails);

            // Check response and handle accordingly
            if (savedWebsite) {
                // Reset unsaved changes flag
                setHasUnsavedChanges(false);
                setWebsiteNameDialogOpen(false);

                // Optional: Show success notification
                console.log('Website saved successfully', savedWebsite);

                // Close editor
                onClose();
            } else {
                console.error('Failed to save website');
                // Optional: Show error notification
            }
        } catch (error) {
            console.error('Error saving website:', error);
            // Optional: Show error notification
        }
    };

    const handleClose = () => {
        // If there are unsaved changes, show warning
        if (hasUnsavedChanges) {
            setSaveWarningOpen(true);
        } else {
            // No unsaved changes, directly close
            onClose();
        }
    };

    const handleDiscardChanges = () => {
        setSaveWarningOpen(false);
        onClose();
    };

    const handleJsCodeSave = () => {
        const scriptManager = editorRef.current.Scripts;
        scriptManager.add({ content: jsCode });
        setJsCodeDialogOpen(false);
        setHasUnsavedChanges(true);
    };

    const toggleEditorSetting = (setting) => {
        const updatedSettings = { ...editorSettings, [setting]: !editorSettings[setting] };
        setEditorSettings(updatedSettings);

        // Apply settings to editor
        const editor = editorRef.current;
        if (setting === 'showBorders') {
            editor.setComponents(editor.getComponents(), { 
                avoidStore: true, 
                avoidUpdateStyle: !updatedSettings.showBorders 
            });
        }
        // Add more setting toggles as needed
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            bgcolor: '#f0f2f5', 
            color: '#333',
            p: 2, 
            gap: 2
        }}>
            <Box sx={{
                flex: 1,
                width: '100%',
                overflow: 'auto',
                bgcolor: '#ffffff', 
                boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.1)',
                borderRadius: 2, 
                p: 2
            }}>
                <div id="gjs" className='pl-2' />
            </Box>
            <AppBar
                position="static"
                color="default"
                elevation={1}
                sx={{
                    top: 'auto',
                    bottom: 0,
                    bgcolor: '#f0f2f5', 
                    borderTop: '1px solid #e0e0e0',
                    borderRadius: 2, 
                    p: 1
                }}
            >
                <Toolbar sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                }}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Tooltip title="Undo">
                            <IconButton onClick={() => editorRef.current?.Commands.run('core:undo')}>
                                <UndoIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Redo">
                            <IconButton onClick={() => editorRef.current?.Commands.run('core:redo')}>
                                <RedoIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Preview">
                            <IconButton onClick={() => editorRef.current?.Commands.run('core:preview')}>
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Editor Settings">
                            <IconButton onClick={() => setSettingsDialogOpen(true)}>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                    }}>
                        <Button
                            variant="outlined"
                            startIcon={<CloseIcon />}
                            onClick={handleClose}
                            sx={{ width: 120 }}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={handleSave}
                            sx={{ width: 120 }}
                        >
                            Save
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>

            {/* JavaScript Code Dialog */}
            <Dialog 
                open={jsCodeDialogOpen} 
                onClose={() => setJsCodeDialogOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Add JavaScript Code</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="JavaScript Code"
                        type="text"
                        fullWidth
                        multiline
                        rows={10}
                        variant="outlined"
                        value={jsCode}
                        onChange={(e) => setJsCode(e.target.value)}
                        placeholder="// Write your JavaScript code here"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setJsCodeDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleJsCodeSave} color="primary" variant="contained">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Editor Settings Dialog */}
            <Dialog 
                open={settingsDialogOpen} 
                onClose={() => setSettingsDialogOpen(false)}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Editor Settings</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
                        <Button 
                            variant={editorSettings.showBorders ? 'contained' : 'outlined'}
                            onClick={() => toggleEditorSetting('showBorders')}
                        >
                            {editorSettings.showBorders ? 'Hide' : 'Show'} Borders
                        </Button>
                        <Button 
                            variant={editorSettings.gridSnap ? 'contained' : 'outlined'}
                            onClick={() => toggleEditorSetting('gridSnap')}
                        >
                            {editorSettings.gridSnap ? 'Disable' : 'Enable'} Grid Snap
                        </Button>
                        <Button 
                            variant={editorSettings.responsiveView ? 'contained' : 'outlined'}
                            onClick={() => toggleEditorSetting('responsiveView')}
                        >
                            {editorSettings.responsiveView ? 'Disable' : 'Enable'} Responsive View
                        </Button>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSettingsDialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Website Name Dialog */}
            <Dialog 
                open={websiteNameDialogOpen} 
                onClose={() => setWebsiteNameDialogOpen(false)}
            >
                <DialogTitle>Name Your Website</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Website Name"
                        fullWidth
                        variant="outlined"
                        value={websiteName}
                        onChange={(e) => setWebsiteName(e.target.value)}
                        placeholder="Enter a name for your website"
                    />
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setWebsiteNameDialogOpen(false)} 
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button 
                        onClick={() => {
                            performSave();
                        }} 
                        color="primary" 
                        variant="contained"
                        disabled={!websiteName}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Save Warning Dialog */}
            <Dialog
                open={saveWarningOpen}
                onClose={() => setSaveWarningOpen(false)}
            >
                <DialogTitle>Unsaved Changes</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You have unsaved changes. Do you want to save them before closing?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={handleDiscardChanges} 
                        color="primary"
                    >
                        Discard Changes
                    </Button>
                    <Button 
                        onClick={() => {
                            setSaveWarningOpen(false);
                            handleSave();
                        }} 
                        color="primary" 
                        variant="contained"
                    >
                        Save Changes
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default Editor;
