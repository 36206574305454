import React from "react";
import { 
  Box, 
  Button, 
  Typography, 
  Grid, 
  Paper, 
  useTheme, 
  useMediaQuery, 
  Tooltip,
  Modal,
  IconButton
} from "@mui/material";
import { InnerTextField } from "components/InputFields";
import { useEffect, useState } from "react";
import { formatDateTime } from "utils/commonFunctions";
import EditConnectModal from "../EditConnectModal";
import { editIcon, trashIcon } from "constants/appImages";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CloseIcon from '@mui/icons-material/Close';
import { DeleteModal } from "components/Modals";

export const Detail = ({ open, onClose, item, refresh }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const [connectionDetails, setConnectionDetails] = useState({});
    const [image, setImage] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [imagePreview, setImagePreview] = useState(false);

    useEffect(() => {
        const { imageURL, ...restItemId } = item || {};
        setConnectionDetails(restItemId);
        if (imageURL) {
            setImage(imageURL);
        }
    }, [item]);

    const handleOpen = () => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleImagePreview = () => {
        setImagePreview(!imagePreview);
    };

    const handleDelete = async () => {
        // Implement delete functionality if needed
        console.log('Delete connection');
    };

    return (
        <Box 
            sx={{ 
                width: '100%', 
                maxWidth: { xs: '100%', sm: '500px', md: '600px' },
                margin: 'auto',
                padding: theme.spacing(2)
            }}
        >
            <Box 
                display="flex" 
                justifyContent="space-between" 
                alignItems="center" 
                marginBottom={2}
            >
                <Typography 
                    fontSize={'18px'} 
                    fontWeight={600} 
                    color={'black'}
                >
                    Connection Details
                </Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Tooltip title="Edit Connection">
                        <Button
                            onClick={handleOpen}
                            sx={{ 
                                minWidth: 'auto', 
                                p: 0.5,
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    transform: 'scale(1.1)'
                                }
                            }}
                            disableRipple
                        >
                            <img src={editIcon} alt="editIcon" width={23} height={23} />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Delete Connection">
                        <Button
                            onClick={() => setDeleteModalOpen(true)}
                            sx={{ 
                                minWidth: 'auto', 
                                p: 0.5,
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    transform: 'scale(1.1)'
                                }
                            }}
                            disableRipple
                        >
                            <img src={trashIcon} alt="deleteIcon" width={23} height={23} />
                        </Button>
                    </Tooltip>
                </Box>
            </Box>

            <EditConnectModal
                open={openModal}
                onClose={handleClose}
                itemId={item}
                refresh={refresh}
            />

            <DeleteModal
                open={deleteModalOpen}
                close={() => setDeleteModalOpen(false)}
                placeholder="Connection"
                deleteFunction={handleDelete}
            />

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <InnerTextField
                        required
                        fullWidth
                        name="connectionName"
                        label="Connection Name"
                        value={connectionDetails?.connectionName || ''}
                        readOnly
                        sx={{
                            '& .MuiInputBase-input': {
                                WebkitUserSelect: 'text',
                                userSelect: 'text',
                                cursor: 'text',
                            }
                        }}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <InnerTextField
                        required
                        fullWidth
                        name="connectionUrl"
                        label="Connection URL"
                        value={connectionDetails?.connectionUrl || ''}
                        readOnly
                        sx={{
                            '& .MuiInputBase-input': {
                                WebkitUserSelect: 'text',
                                userSelect: 'text',
                                cursor: 'text',
                            }
                        }}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                        }}
                    />
                </Grid>
            </Grid>

            <Box marginY={2}>
                <Box 
                    display="flex" 
                    alignItems="center" 
                    justifyContent="space-between" 
                    marginBottom={1}
                >
                    <Typography 
                        variant="subtitle1" 
                        fontWeight={500}
                        sx={{ 
                            fontSize: '16px', 
                            color: 'black' 
                        }}
                    >
                        Connection Image
                    </Typography>
                </Box>
                <Box 
                    sx={{
                        position: 'relative',
                        width: '100%',
                        maxWidth: { xs: '250px', sm: '300px', md: '400px' },
                        aspectRatio: '16/9',
                        borderRadius: 2,
                        overflow: 'hidden',
                        boxShadow: 2,
                        transition: 'transform 0.3s',
                        '&:hover': {
                            transform: 'scale(1.02)',
                        }
                    }}
                >
                    {image ? (
                        <>
                            <img 
                                src={image} 
                                alt="Connection Image" 
                                style={{
                                    width: '100%', 
                                    height: '100%', 
                                    objectFit: 'cover'
                                }} 
                            />
                            <Tooltip title="View Full Image">
                                <Button
                                    onClick={handleImagePreview}
                                    sx={{
                                        position: 'absolute',
                                        top: 8,
                                        right: 8,
                                        backgroundColor: 'rgba(255,255,255,0.7)',
                                        minWidth: 'auto',
                                        p: 1,
                                        borderRadius: '50%',
                                        '&:hover': {
                                            backgroundColor: 'rgba(255,255,255,0.9)',
                                        }
                                    }}
                                >
                                    <ZoomInIcon fontSize="small" />
                                </Button>
                            </Tooltip>
                        </>
                    ) : (
                        <Typography 
                            variant="body2" 
                            color="text.secondary"
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            No image uploaded
                        </Typography>
                    )}
                </Box>
            </Box>

            <Box marginTop={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Box 
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'column',
                                backgroundColor: theme.palette.background.default,
                                padding: theme.spacing(1.5),
                                borderRadius: 2
                            }}
                        >
                            <Typography 
                                variant="subtitle2" 
                                color="black" 
                                fontWeight={600}
                                marginBottom={0.5}
                            >
                                Status
                            </Typography>
                            <Typography variant="body2">
                                {connectionDetails?.status || 'Not specified'}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box 
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'column',
                                backgroundColor: theme.palette.background.default,
                                padding: theme.spacing(1.5),
                                borderRadius: 2
                            }}
                        >
                            <Typography 
                                variant="subtitle2" 
                                color="black" 
                                fontWeight={600}
                                marginBottom={0.5}
                            >
                                Created Time
                            </Typography>
                            <Typography variant="body2">
                                {formatDateTime(connectionDetails?.createdTime) || 'Not available'}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box 
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'column',
                                backgroundColor: theme.palette.background.default,
                                padding: theme.spacing(1.5),
                                borderRadius: 2
                            }}
                        >
                            <Typography 
                                variant="subtitle2" 
                                color="black" 
                                fontWeight={600}
                                marginBottom={0.5}
                            >
                                Modified Time
                            </Typography>
                            <Typography variant="body2">
                                {formatDateTime(connectionDetails?.modifiedTime) || 'Not modified'}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            {/* Full Image Preview Modal */}
            {imagePreview && (
                <Modal
                    open={imagePreview}
                    onClose={handleImagePreview}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box 
                        sx={{
                            maxWidth: '90%',
                            maxHeight: '90%',
                            outline: 'none',
                            position: 'relative',
                        }}
                    >
                        <IconButton
                            onClick={handleImagePreview}
                            sx={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: 'rgba(0,0,0,0.7)',
                                }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <img 
                            src={image} 
                            alt="Full Connection Image" 
                            style={{
                                width: '100%', 
                                height: '100%', 
                                objectFit: 'contain',
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }} 
                        />
                    </Box>
                </Modal>
            )}
        </Box>
    );
};

export default Detail;
