import React, { useEffect, useState } from 'react';
import { Box, Switch, Typography, TextField, Modal, IconButton, Tooltip, Button } from '@mui/material';
import { imageFileInput } from './Style';
import toast from 'react-hot-toast';
import { UpdateTemplate } from 'Api/Api';
import { Loading, SkeletonLoading } from 'components/Loading';
import { SideDrawer } from 'components/SideDrawer';
import { InnerTextField } from 'components/InputFields';
import { formatDateTime } from 'utils/commonFunctions';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CloseIcon from '@mui/icons-material/Close';

const EditTemplateModal = ({ open, onClose, itemId, isDetail, refresh, refreshTemplate }) => {
  const [templateDetails, setTemplateDetails] = useState(itemId || {});
  const [updatedFields, setUpdatedFields] = useState(() => ({
    id: itemId?.id,
    welcomeTemplate: itemId?.welcomeTemplate ?? '',
  }));

  const [images, setImages] = useState({
    banner: itemId?.banner || '',
    icon: itemId?.icon || '',
  });

  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [errors, setErrors] = useState({
    templateName: false,
    title: false,
    message: false,
    pageURL: false,
    icon: false,
    banner: false,
  });

  const [imagePreview, setImagePreview] = useState(false);

  const handleEditChange = () => setEdit(!edit);

  const handleToggle = (e) => {
    if (e.target.name === 'status') {
      const newStatus =
        templateDetails.status === 'Active' ? 'Inactive' : 'Active';
      setTemplateDetails((prev) => ({ ...prev, status: newStatus }));
      setUpdatedFields((prev) => ({ ...prev, status: newStatus }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name !== 'banner' && name !== 'icon') {
      setTemplateDetails((prev) => ({ ...prev, [name]: value }));
      setUpdatedFields((prev) => ({ ...prev, [name]: value }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === '',
      }));
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      setTemplateDetails((prev) => ({ ...prev, [name]: file }));
      setUpdatedFields((prev) => ({ ...prev, [name]: file }));
      setImages((prev) => ({ ...prev, [name]: URL.createObjectURL(file) }));
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: true }));
    }
  };

  const handleImagePreview = () => {
    setImagePreview(!imagePreview);
  };

  const handleSubmit = async () => {
    const newErrors = ['templateName', 'title', 'message', 'pageURL'].reduce(
      (acc, field) => {
        if (!templateDetails[field]?.trim()) {
          acc[field] = true;
        }
        return acc;
      },
      {}
    );
    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => !error)) {
      try {
        setLoading(true);
        let formData = new FormData();
        Object.entries(updatedFields).forEach(([key, value]) => {
          formData.append(key, value);
        });
        const response = await UpdateTemplate(templateDetails?.id, formData);
        if (response?.data?.status === true) {
          toast.success(response?.data?.message);
          if (refreshTemplate !== undefined) {
            refreshTemplate()
          }
          refresh();
          onClose();
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    const { banner, icon, ...restItemId } = itemId || {};
    setTemplateDetails((prev) => ({ ...prev, ...restItemId }));

    if (itemId?.banner || itemId?.icon) {
      setImages({ banner: itemId.banner, icon: itemId.icon });
    }
    setUpdatedFields(() => ({
      id: itemId?.id,
      welcomeTemplate: itemId?.welcomeTemplate,
    }));
  }, [itemId]);

  return (
    <>
      <Loading state={loading} />
      <SideDrawer
        open={open}
        onClose={onClose}
        isDetail={isDetail}
        edit={edit}
        setEdit={handleEditChange}
        title={!edit && isDetail ? 'Template Details' : 'Edit Template'}
        handleSubmit={handleSubmit}
      >
        <Box>
          <InnerTextField
            required
            error={errors.templateName}
            name="templateName"
            label="Template Name"
            value={templateDetails?.templateName || ''}
            placeholder="Enter template name"
            onChange={handleChange}
            helperText={errors.templateName ? 'Field required' : ''}
            readOnly={!edit && isDetail}
          />
        </Box>
        <Box>
          <InnerTextField
            required
            error={errors.title}
            name="title"
            label="Title"
            value={templateDetails?.title || ''}
            onChange={handleChange}
            helperText={errors.title ? 'Field required' : ''}
            readOnly={!edit && isDetail}
          />
        </Box>
        <Box>
          <InnerTextField
            required
            error={errors.pageURL}
            name="pageURL"
            label="Redirecting URL"
            value={templateDetails?.pageURL || ''}
            placeholder="http://example.com"
            onChange={handleChange}
            helperText={errors.pageURL ? 'Field required' : ''}
            readOnly={!edit && isDetail}
          />
        </Box>
        <Box mb={2}>
          <InnerTextField
            required
            multiline
            error={errors.message}
            name="message"
            label="Message"
            rows={2}
            value={templateDetails?.message || ''}
            onChange={handleChange}
            helperText={errors.message ? 'Field required' : ''}
            readOnly={!edit && isDetail}
          />
        </Box>
        <Typography variant="subtitle3">Upload Notification Icon </Typography>
        <Box>
          <Box 
            sx={{
              position: 'relative',
              width: '100%',
              maxWidth: { xs: '250px', sm: '300px', md: '400px' },
              aspectRatio: '16/9',
              borderRadius: 2,
              overflow: 'hidden',
              boxShadow: 2,
              transition: 'transform 0.3s',
              '&:hover': {
                transform: 'scale(1.02)',
              }
            }}
          >
            {images.icon ? (
              <img 
                src={images.icon} 
                alt="Icon Image" 
                style={{
                  width: '100%', 
                  height: '100%', 
                  objectFit: 'cover'
                }} 
              />
            ) : (
              <Typography 
                variant="body2" 
                color="text.secondary"
              >
                No image uploaded
              </Typography>
            )}
            <Tooltip title="View Full Image">
              <Button
                onClick={handleImagePreview}
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  backgroundColor: 'rgba(255,255,255,0.7)',
                  minWidth: 'auto',
                  p: 1,
                  borderRadius: '50%',
                  '&:hover': {
                    backgroundColor: 'rgba(255,255,255,0.9)',
                  }
                }}
              >
                <ZoomInIcon fontSize="small" />
              </Button>
            </Tooltip>
          </Box>
          <input 
            type="file" 
            name="icon" 
            accept="image/*" 
            onChange={handleFileChange} 
            style={{ marginTop: '10px' }}
            disabled={!edit && isDetail}
          />
        </Box>
        <br />
        <Typography variant="subtitle3">Upload Banner Image</Typography>
        <Box>
          <Box 
            sx={{
              position: 'relative',
              width: '100%',
              maxWidth: { xs: '250px', sm: '300px', md: '400px' },
              aspectRatio: '16/9',
              borderRadius: 2,
              overflow: 'hidden',
              boxShadow: 2,
              transition: 'transform 0.3s',
              '&:hover': {
                transform: 'scale(1.02)',
              }
            }}
          >
            {images.banner ? (
              <img 
                src={images.banner} 
                alt="Banner Image" 
                style={{
                  width: '100%', 
                  height: '100%', 
                  objectFit: 'cover'
                }} 
              />
            ) : (
              <Typography 
                variant="body2" 
                color="text.secondary"
              >
                No image uploaded
              </Typography>
            )}
            <Tooltip title="View Full Image">
              <Button
                onClick={handleImagePreview}
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  backgroundColor: 'rgba(255,255,255,0.7)',
                  minWidth: 'auto',
                  p: 1,
                  borderRadius: '50%',
                  '&:hover': {
                    backgroundColor: 'rgba(255,255,255,0.9)',
                  }
                }}
              >
                <ZoomInIcon fontSize="small" />
              </Button>
            </Tooltip>
          </Box>
          <input 
            type="file" 
            name="banner" 
            accept="image/*" 
            onChange={handleFileChange} 
            style={{ marginTop: '10px' }}
            disabled={!edit && isDetail}
          />
        </Box>
        <Box>
          <Box>
            <Typography variant="black_4">Status</Typography>
            <Switch
              name="status"
              size="large"
              checked={templateDetails?.status === 'Active'}
              onChange={handleToggle}
              readOnly={!edit && isDetail}
            />
          </Box>
        </Box>
        <Box>
          {isDetail && !edit ? (
            <Box>
              <Typography variant="subtitle2" my={1}>
                Created time : {formatDateTime(templateDetails?.createdTime)}
              </Typography>
              <Typography variant="subtitle2" my={1}>
                Modified time : {formatDateTime(templateDetails?.modifiedTime)}
              </Typography>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </SideDrawer>

      {/* Full Image Preview Modal */}
      {imagePreview && (images.icon || images.banner) && (
        <Modal
          open={imagePreview}
          onClose={handleImagePreview}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box 
            sx={{
              maxWidth: '90%',
              maxHeight: '90%',
              outline: 'none',
              position: 'relative',
            }}
          >
            <IconButton
              onClick={handleImagePreview}
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                backgroundColor: 'rgba(0,0,0,0.5)',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.7)',
                }
              }}
            >
              <CloseIcon />
            </IconButton>
            <img 
              src={images.icon || images.banner} 
              alt="Full Image" 
              style={{
                width: '100%', 
                height: '100%', 
                objectFit: 'contain',
                maxWidth: '100%',
                maxHeight: '100%',
              }} 
            />
          </Box>
        </Modal>
      )}
    </>
  );
};

export default EditTemplateModal;
