import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { FetchAllSegment } from 'Api/Api';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AddBox from '@mui/icons-material/AddBox';
import { updateSubscribers } from '../../../redux/reducers/sequenceReducer';
import { InnerTextField } from 'components/InputFields';
import { CampaignStyle } from '../WebCampaigns/Style';
import CreateSegmentModal from '../Segments/CreateSegmentModal';

const SequenceSubscribers = ({ handleBack, handleNext }) => {
  const dispatch = useDispatch();
  let { id } = useParams();
  let navigate = useNavigate();

  const subscriber = useSelector((state) => state.sequence.subscribers);
  const [segmentDetails, setSegmentDetails] = useState([]);
  const [segmentModalOpen, setSegmentModalOpen] = useState(false);
  const [details, setDetails] = useState({
    sequenceName: subscriber?.sequenceName || '',
    selectedSegment: subscriber?.selectedSegment || null,
    subscriberCount: subscriber?.subscriberCount || null,
  });
  const [error, setError] = useState({
    segment: false,
    sequenceName: false,
  });

  async function getSegment() {
    try {
      const response = await FetchAllSegment();
      if (response?.data?.status === true) {
        setSegmentDetails(response?.data?.data);
      }
    } catch (error) {}
  }

  const PreviousPage = () => {
    handleBack();
    navigate('/sequence/');
  };

  const NextPage = () => {
    const handleError = () => {
      setError((prevError) => ({
        ...prevError,
        sequenceName: details.sequenceName === '',
      }));
      setError((prevError) => ({
        ...prevError,
        segment: details.selectedSegment == null,
      }));
    };
    if (details.sequenceName === '' || details.selectedSegment == null) {
      handleError();
      return;
    }
    dispatch(updateSubscribers(details));
    handleNext();
  };

  useEffect(() => {
    getSegment();
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        width: '100%',
      }}
    >
      <Box sx={{ width: '40%', minHeight: '70vh' }}>
        <Box mt={6}>
          <Typography pl={2} variant="black_h4" sx={{ fontSize: 20 }}>
            Sequence name
          </Typography>
        </Box>
        <Box pl={2} sx={{ width: '91%' }}>
          <InnerTextField
            required
            error={error.sequenceName}
            placeholder="Sequence name"
            value={details?.sequenceName}
            onChange={(e) => {
              setDetails((prev) => ({ ...prev, sequenceName: e.target.value }));
              if (e.target.value) {
                setError((prev) => ({ ...prev, sequenceName: false }));
              }
            }}
            helperText={error.sequenceName && 'Field required'}
          />
        </Box>

        <Box my={2}>
          <Typography pl={2} variant="black_h4" sx={{ fontSize: 20 }}>
            Add Segment
          </Typography>
        </Box>

        <Box
          pl={2}
          mt={3}
          sx={{
            display: 'flex',
            alignItems: 'center', // Center elements vertically
            width: '100%',
            justifyContent: 'center',
            mb: 1,
          }}
        >
          <Autocomplete
            disablePortal
            options={segmentDetails}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option?.segmentName}
            value={
              segmentDetails?.find(
                (segment) => segment.id === details?.selectedSegment
              ) || null
            }
            onChange={(e, newValue) => {
              setDetails((prev) => ({
                ...prev,
                selectedSegment: newValue ? parseInt(newValue?.id) : null,
                subscriberCount: newValue
                  ? parseInt(newValue?.subscriberCount)
                  : null,
              }));
              setError((prev) => ({ ...prev, segment: !newValue }));
            }}
            noOptionsText={
              <span>
                No match found. Please create one by{' '}
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setSegmentModalOpen(true);
                  }}
                  style={{
                    color: '#1976d2',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  click here
                </a>
                .
              </span>
            }
            sx={CampaignStyle.autoSelectStyle}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Segment"
                error={error.segment}
                helperText={error.segment && 'Field required'}
                InputLabelProps={{ style: { color: 'black' } }}
                InputProps={{
                  ...params.InputProps,
                  style: { color: 'black' },
                }}
                sx={{ minHeight: '80px' }}
              />
            )}
            renderOption={(props, option) => (
              <li
                {...props}
                key={option.id}
                style={{ color: 'black', cursor: 'pointer' }}
              >
                {option.segmentName}
              </li>
            )}
          />
          <IconButton
            sx={{
              height: '56px',
              width: '56px',
              mb: 2,
            }}
            onClick={() => setSegmentModalOpen(true)}
          >
            {' '}
            <AddBox
              sx={{
                color: '#058270',
                height: '50px',
                width: '50px',
                marginBottom: '10px',
              }}
            />
          </IconButton>
          <CreateSegmentModal
            refresh={getSegment}
            open={segmentModalOpen}
            onClose={() => setSegmentModalOpen(false)}
          />
        </Box>

        <Box my={2}>
          <Typography pl={2} variant="black_h4" sx={{ fontSize: 20 }}>
            Subscriber Count = {details?.subscriberCount}
          </Typography>
        </Box>

        <Box
          sx={{
            width: '100%',
            position: 'absolute',
            bottom: -40,
            display: 'flex',
            gap: 4,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button variant="outlined" size="large" onClick={PreviousPage}>
            Cancel
          </Button>
          <Button variant="contained" size="large" onClick={NextPage}>
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SequenceSubscribers;
