import React, { useState, useEffect } from 'react';
import { Box, Slide, Stack, Button, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import ListView from 'components/ListView';
import { DeleteModal } from 'components/Modals';
import { ContainerStyle, MainDashboard } from 'components/Style';
import { SurveyTableColumns } from 'constants/appConstant';
import NoRecord from 'components/NoRecord';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import FormBuilder from './FormBuilder';
import { FetchAllSurveys, DeleteSurvey, UpdateSurvey } from 'Api/Api'; //TODO: Complete these functions

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Survey = () => {
  const [surveys, setSurveys] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [editId, setEditId] = useState(null);
  const [searchTerms, setSearchTerms] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);

  const fetchSurveys = async () => {
    try {
      setInitialLoading(true);
      const response = await FetchAllSurveys();
      if(response?.data?.status === true){
        setSurveys(response?.data?.data);
      }
    } catch (error) {
      toast.error('Failed to fetch surveys');
    } finally{
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    fetchSurveys();
    onClose();
  }, []);

  const handleCreateModal = () => {
    setCreateModalOpen(true);
  };

  const handleDeleteModel = (surveyId) => {
    setDeleteModalOpen(true);
    setDeleteId(surveyId);
  };

  const handleEditModel = (surveyId) => {
    setEditModalOpen(true);
    setEditId(surveyId);
  };

  const handleDelete = async () => {
    try {
      const response = await DeleteSurvey(deleteId);
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        fetchSurveys();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setDeleteModalOpen(false);
    }
  };

  const updateStatus = async (item) => {
    setLoading((prevLoading) => ({
      ...prevLoading,
      [item.id]: true,
    }));
    try {
      const response = await UpdateSurvey(item.id, {
        id: item.id,
        name: item.name,
        status: item.status === 'Active' ? 'Inactive' : 'Active',
      });
      if (response?.data?.status === true) {
        fetchSurveys();
      }
    } catch (error) {
      toast.error('Failed to update status.');
    } finally {
      setLoading((prevLoading) => ({
        ...prevLoading,
        [item.id]: false,
      }));
    }
  };

  const onClose = () => {
    setCreateModalOpen(false);
    setEditModalOpen(false);
    setDeleteModalOpen(false);
  };

  return createModalOpen ? (
    <FormBuilder
      onClose={() => setCreateModalOpen(false)}
      onSave={(newSurvey) => {
        setSurveys([...surveys, newSurvey]);
        setCreateModalOpen(false);
      }}
    />
  ) : (
    <MainDashboard>
      <Box sx={ContainerStyle.container}>
        <Stack direction="row" sx={ContainerStyle.topBar}>
          <Typography variant="h5" sx={ContainerStyle.heading}>
            Surveys
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddToPhotosIcon />}
            onClick={handleCreateModal}
            size="large"
            sx={{ width: '150px', marginLeft: '16px', marginBottom: '6px' }}
          >
            Create
          </Button>
        </Stack>
        <Box sx={ContainerStyle.listView}>
          <ListView
            tableData={surveys}
            rowData={SurveyTableColumns}
            handleEditModel={handleEditModel}
            handleDeleteModel={handleDeleteModel}
            updateStatus={updateStatus}
            initialLoading={initialLoading}
            loading={loading}
            sortingIndex={[
              'status',
              'name',
              'surveyId',
              'createdTime',
              'modifiedTime'
            ]}
            searchTerms={searchTerms}
            setSearchTerms={setSearchTerms}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            noRecordComponent={
              <NoRecord
                type="callback"
                moduleName="Survey"
                onAction={handleCreateModal}
              />
            }
          />
        </Box>
        <DeleteModal
          open={deleteModalOpen}
          close={() => setDeleteModalOpen(false)}
          placeholder="Survey"
          deleteFunction={handleDelete}
        />
      </Box>
    </MainDashboard>
  );
};

export default Survey;
