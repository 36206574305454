import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Zoom,
} from '@mui/material';
import DateTimepicker from 'components/DateTimePicker';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateOptions } from '../../../redux/reducers/sequenceReducer';
import { CustomSelect } from 'components/CustomSelect';
import { SequenceScheuduler } from 'constants/appConstant';
import { getCurrentDateTimeLocal } from 'utils/commonFunctions';
import InfoIcon from '@mui/icons-material/Info';
import CustomTooltip from 'components/Tooltip';
import { CampaignStyle } from '../WebCampaigns/Style';

const SequenceOption = ({ handleBack, handleNext }) => {
  const dispatch = useDispatch();

  const [formState, setFormState] = useState({
    schedule: 'now',
    frequency: '',
    triggerCampaign: false,
    subscribersInteractionNeeded: false,
    frequencyDateTime: '',
    selectedCountry: null,
  });
  const [countries, setCountries] = useState([]);
  const [errors, setErrors] = useState({});

  const handleScheduleChange = (event) => {
    const { value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      schedule: value,
      frequency: value === 'now' ? 'now' : prevState.frequency,
    }));
  };

  const handleFrequencyChange = (event) => {
    const { value } = event.target;
    setFormState((prev) => ({
      ...prev,
      frequency: value,
      frequencyDateTime:
        value !== 'on_specific_date'
          ? getCurrentDateTimeLocal()
          : prev.frequencyDateTime,
    }));
    setErrors((prev) => ({ ...prev, frequency: !value.trim() }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const fetchCountries = useCallback(async () => {
    try {
      const response = await fetch('https://restcountries.com/v3.1/all');
      const data = await response.json();
      const countryNames = data.map((country) => ({
        label: country.name.common,
      }));
      setCountries([{ label: 'Subscriber Local Timezone' }, ...countryNames]);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  }, []);

  const NextPage = () => {
    dispatch(updateOptions({ formState }));
    handleNext();
  };

  useEffect(() => {
    if (formState.schedule === 'now') {
      setFormState((prev) => ({
        ...prev,
        frequency: 'now',
        frequencyDateTime: getCurrentDateTimeLocal(),
      }));
    }
  }, [formState.schedule]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  // useEffect(() => {
    // console.log('countries:', countries);
    // console.log('formState:', formState);
  // }, [formState]);

  return (
    <Box
      sx={{
        position: 'relative',
        height: '75vh',
        width: '100%',
      }}
    >
      <Box p={3} gap={4} sx={{ width: '85%' }}>
        <Typography p={0} variant="black_h4" sx={{ fontSize: 25 }}>
          Iteration
        </Typography>

        <Box mt={1} ml={2} width={'100%'}>
          <RadioGroup
            aria-labelledby="schedule-radio-buttons-group-label"
            name="schedule"
            value={formState?.schedule}
            onChange={handleScheduleChange}
          >
            <Box>
              <FormControlLabel
                value="now"
                control={<Radio />}
                label="Send Now"
                sx={{
                  '.MuiFormControlLabel-label': {
                    fontWeight: '500',
                    fontSize: '1.2rem',
                    color: 'black',
                  },
                }}
              />
            </Box>

            <Box
              mb={0}
              height={'60px'}
              display={'flex'}
              justifyContent={'left'}
              alignItems={'center'}
            >
              <Box width={'20%'}>
                <FormControlLabel
                  value="later"
                  control={<Radio />}
                  label="Schedule Later"
                  sx={{
                    '.MuiFormControlLabel-label': {
                      fontWeight: '500',
                      fontSize: '1.2rem',
                      color: 'black',
                    },
                  }}
                />
              </Box>

              {formState?.schedule === 'later' && (
                <Box
                  width="60%"
                  display={'flex'}
                  justifyContent={'flex-start'}
                  alignItems={'end'}
                  gap={4}
                  ml={1}
                >
                  <FormControl sx={{ minWidth: '45%' }}>
                    <CustomSelect
                      label="Schedule"
                      margin="normal"
                      error={errors.frequency}
                      options={SequenceScheuduler}
                      value={formState?.frequency}
                      onChange={handleFrequencyChange}
                      helperText={errors.frequency ? 'Field required' : ''}
                    />
                  </FormControl>

                  <FormControl sx={{ minWidth: '50%' }}>
                    <DateTimepicker
                      onChangeval={(e) => {
                        setFormState({
                          ...formState,
                          frequencyDateTime: e,
                        });
                      }}
                    />
                  </FormControl>
                </Box>
              )}
            </Box>
          </RadioGroup>
        </Box>
      </Box>

      <Box>
        <Box display="flex" alignItems="center" ml={3} mb={3}>
          <Typography variant="black_h4" sx={{ fontSize: 25, mr: 1 }}>
            Subscriber Time Zone
          </Typography>
          <CustomTooltip
            title="This option triggers the workflow according to the subscriber's time zone."
            arrow
            placement="top"
            TransitionComponent={Zoom}
          >
            <IconButton size="small" sx={{ padding: 0 }}>
              <InfoIcon fontSize="small" />
            </IconButton>
          </CustomTooltip>
        </Box>

        {/* Autocomplete Section */}
        <Box ml={3} mt={1}>
          <FormControl fullWidth sx={{ maxWidth: '400px' }}>
            <Autocomplete
              disablePortal
              autoHighlight
              options={countries}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              getOptionLabel={(option) => option.label}
              onChange={(e, newValue) => {
                setFormState((prev) => ({
                  ...prev,
                  selectedCountry: newValue ? newValue.label : null,
                }));
                setErrors((prev) => ({
                  ...prev,
                  selectedCountry: !newValue,
                }));
              }}
              sx={CampaignStyle.autoSelectStyle}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a country"
                  error={errors.selectedCountry}
                  helperText={errors.selectedCountry && 'Field required'}
                  InputLabelProps={{ style: { color: 'black' } }}
                  InputProps={{
                    ...params.InputProps,
                    autoComplete: 'new-password',
                    style: { color: 'black' },
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={`${option.label}-1`}
                  style={{ color: 'black' }}
                >
                  {option.label}
                </li>
              )}
            />
          </FormControl>
        </Box>

        {/* Subscribers Interaction Checkbox Section */}
        <Box ml={3} mt={3}>
          <FormGroup>
            <Box display="flex" alignItems="center">
              <Checkbox
                checked={formState?.subscribersInteractionNeeded}
                onChange={handleCheckboxChange}
                name="subscribersInteractionNeeded"
              />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '1.2rem',
                  color: 'black',
                }}
              >
                Subscribers Interaction Needed
              </Typography>

              <CustomTooltip
                title="This option requires interaction from subscribers to proceed."
                arrow
                placement="right"
                TransitionComponent={Zoom}
              >
                <IconButton size="small" sx={{ padding: 0, ml: 1 }}>
                  <InfoIcon fontSize="small" />
                </IconButton>
              </CustomTooltip>
            </Box>
          </FormGroup>
        </Box>
      </Box>

      <Box
        mt={2}
        sx={{
          width: '100%',
          position: 'absolute',
          bottom: -40,
          height: '60px',
          display: 'flex',
          gap: 4,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f0f2f5',
        }}
      >
        <Button variant="outlined" size="large" onClick={handleBack}>
          Cancel
        </Button>
        <Button variant="contained" size="large" onClick={NextPage}>
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default SequenceOption;
